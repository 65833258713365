<template>
  <div>
    <van-nav-bar
        title="会员详情"
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
    />
    <div class="m-d">
      <div class="m-d-a">
        <div class="m-d-l">
          <template v-if="memberStore.member"><img :src="memberStore.member.avatar" /></template>
          <template v-else><img src="../../../assets/images/user_def.png" /></template>
        </div>
        <div class="m-d-r">
          <h3 v-if="memberStore.member">{{memberStore.member.nickname}}</h3>
          <div class="m-d-r-m">
            <p>姓 名：{{memberStore.real_name}}<span><template v-if="memberStore.gender == 1">男</template><template v-else-if="memberStore.gender == 2">女</template></span>{{memberStore.age}}岁</p>
            <p v-if="roleId != 11">手机号：{{memberStore.phone}}</p>
          </div>
        </div>
      </div>
      <div class="m-d-b">
        <van-row class="m-d-b-r">
          <van-col span="8">会员等级：<template v-if="memberStore.member_grade">{{memberStore.member_grade.name}}</template><template v-else>无等级</template></van-col>
          <van-col span="8">状态：<template v-if="memberStore.status == 1">正常</template><template v-else>冻结</template></van-col>
          <van-col span="8">生日：{{memberStore.birthday}}</van-col>
        </van-row>
        <van-row class="m-d-b-r">
          <van-col span="12" v-if="roleId != 11">推荐人：{{memberStore.referrer_name}}</van-col>
          <van-col span="12">账户余额：{{memberStore.balance}}</van-col>
        </van-row>
        <van-row class="m-d-b-r">
          <van-col span="24" v-if="memberStore.consult_pic">
            <span class="pic-title">咨询表：</span><img v-for="(img, index) in memberStore.consult_pic" :key="index" :src="img.url" @click="showPhoto(img.url)" class="pic-li" />
          </van-col>
        </van-row>
        <van-popup v-model:show="showConsult"><div class="consult_pic"><img :src="picUrl" /></div></van-popup>
        <van-row class="m-d-b-r" v-if="memberStore.identification && roleId != 11">
          <van-col span="24">身份证号：{{memberStore.identification}}</van-col>
        </van-row>
        <van-row class="m-d-b-r" v-if="memberStore.remarks">
          <van-col span="24">备注：{{memberStore.remarks}}</van-col>
        </van-row>
      </div>
    </div>
    <div class="m-tabs">
      <div class="m-t-l">
        <van-tabs v-model:active="active" color="#1989fa">
          <van-tab title="服务项目">
            <div class="project">
              <div class="p-tr p-hr">
                <div class="p-hr-l">
                  <span class="sp-1">项目名称</span>
                  <span class="sp-2">次数/剩余</span>
                </div>
                <span class="p-hr-r sp-3">操作</span>
              </div>
              <div class="p-tr">
                <div class="p-tr-m" v-for="p in memberStore.projects" :key="p.id">
                  <div class="left l-m" @click="showRecordList(p)">
                    <div class="l-m-m">
                      <span class="sp-1-l">{{p.project_info.name}}</span>
                      <span class="sp-2-l">{{p.project_info.service_number}}/<em>{{p.surplus_count}}</em></span>
                    </div>
                    <div><template v-if="p.pay_type == 'weixin'">微信</template><template v-else-if="p.pay_type == 'alipay'">支付宝</template><template v-else-if="p.pay_type == 'cash'">现金</template>&nbsp;&nbsp;&nbsp;&nbsp;{{p.created_at}}</div>
                  </div>
                  <span class="sp-3"><van-button type="primary" size="mini" class="h-r" @click="addRecord(p)">添加记录</van-button></span>
                </div>
              </div>
            </div>
          </van-tab>
          <van-tab title="营销订单">
            <div class="orders">
              <div class="o-li">
                <div class="o-li-th">
                  <span class="sp-1">订单编号</span>
                  <span class="sp-2">商品名称</span>
                  <span class="sp-3">金额</span>
                </div>
                <div class="o-li-tr" v-for="o in memberStore.marketing_orders" :key="o.id">
                  <span class="sp-1">{{o.order_no}}</span>
                  <span class="sp-2" v-for="c in o.cart_info" :key="c.id">{{c.cart_info.title}}</span>
                  <span class="sp-3">{{o.total_price}}</span>
                </div>
              </div>
            </div>
          </van-tab>
          <van-tab title="团队成员" v-if="roleId != 11">
            <div class="team">
              <div class="th">
                <span class="sp-1">图像</span>
                <span class="sp-2">昵称</span>
                <span class="sp-3">姓名</span>
              </div>
              <div class="tr" v-for="m in memberStore.referrer_list" :key="m.id">
                <span class="sp-1"><template v-if="m.member"><img :src="m.member.avatar" class="img" /></template><template v-else><img src="../../../assets/images/user_def.png" class="img" /></template></span>
                <span class="sp-2">{{m.member.nickname}}</span>
                <span class="sp-3">{{m.real_name}}</span>
              </div>
            </div>
          </van-tab>
          <van-tab title="佣金明细" v-if="roleId != 11">
            <div class="commission">
              <div class="th">
                <span class="sp-1">商品信息</span>
                <span class="sp-2">佣金金额</span>
                <span class="sp-3">返佣日期</span>
              </div>
              <div class="tr" v-for="p in memberStore.members_profit" :key="p.id">
                <span class="sp-1"><template v-if="p.orders">{{p.orders.cart_info[0].cart_info.name}}</template></span>
                <span class="sp-2"><em class="red">{{p.price}}</em></span>
                <span class="sp-3">{{p.add_time}}</span>
              </div>
            </div>
          </van-tab>
        </van-tabs>
      </div>
    </div>
    <!--查看服务记录-->
    <van-popup v-model:show="showRecord" position="bottom" :style="{ height: '60%' }" closeable>
      <div class="record-m">
        <div class="r-m-li" v-for="p in projectList.records" :key="p.id">
          <div class="r-m-l">
            <span class="sp-1">{{p.frequency}}</span>
          </div>
          <div class="r-m-r">
            <div class="r-m-t-1"><span class="left">{{p.staff_name}}</span><span class="right r-l">{{p.service_time}}</span></div>
            <div class="r-m-t-2">脱发情况：{{p.service_situation}}</div>
            <div class="r-m-t-2">白发情况：{{p.bf_situation}}</div>
            <div v-if="p.photo">
              <span v-for="(pic, index) in p.photo" :key="index" @click="showPhoto(pic.url)"><img :src="pic.url" height="50" /></span>
            </div>
            <div class="r-m-t-3">
              <span class="left" v-if="p.remarks">备注：{{p.remarks}}</span>
              <span class="right">{{p.autograph}}</span>
            </div>
          </div>
        </div>
      </div>
    </van-popup>
    <!--添加服务记录-->
    <van-popup v-model:show="showAddRecord" position="bottom" :style="{ height: 'auto' }" closeable>
      <div class="">
        <van-form @submit="onSubmit" class="c-e-form">
          <van-field
                  v-model="record.service_situation"
                  name="service_situation"
                  label="脱发情况"
                  placeholder="请输入脱发情况"
                  :rules="[{ required: true, message: '请填写脱发情况' }]"
          />
          <van-field
                  v-model="record.bf_situation"
                  name="bf_situation"
                  label="白发情况"
                  placeholder="请输入白发情况"
                  :rules="[{ required: true, message: '请填写白发情况' }]"
          />
          <van-field
                  v-model="record.tp_situation"
                  name="tp_situation"
                  label="头皮情况"
                  placeholder="请输入头皮情况"
                  :rules="[{ required: true, message: '请填写头皮情况' }]"
          />
          <van-field
                  v-model="record.staff_name"
                  is-link
                  readonly
                  name="staff_name"
                  label="养发师"
                  placeholder="选择养发师"
                  @click="showPicker = true"
                  :rules="[{ required: true, message: '请选择养发师' }]"
          />
          <van-popup v-model:show="showPicker" position="bottom">
            <van-picker
                    :columns="staffData"
                    :columns-field-names="{text: 'name'}"
                    @confirm="onConfirm"
                    @cancel="showPicker = false"
            />
          </van-popup>
          <van-field
                  v-model="record.service_time"
                  is-link
                  readonly
                  name="service_time"
                  label="服务日期"
                  placeholder="请选择服务日期"
                  @click="showServiceTime = true"
                  :rules="[{ required: true, message: '请选择服务日期' }]"
          />
          <van-calendar v-model:show="showServiceTime" :show-confirm="false" @confirm="onServiceTime" />
          <van-field
              v-model="record.autograph"
              name="autograph"
              label="顾客签名"
              placeholder="请填写签名"
              :rules="[{ required: true, message: '请填写签名' }]"
          />
          <van-field name="photo" label="头发照片">
            <template #input>
              <van-uploader v-model="fileList" :max-count="6" :after-read="afterRead" />
            </template>
          </van-field>
          <van-field name="is_subscribe" label="发送消息提醒">
            <template #input>
              <van-radio-group v-model="record.status" direction="horizontal">
                <van-radio name="是">是</van-radio>
                <van-radio name="否">否</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <van-field
                  v-model="record.remarks"
                  rows="2"
                  autosize
                  label="备注"
                  type="textarea"
                  name="remarks"
                  maxlength="50"
                  placeholder="请输入备注"
                  show-word-limit
          />
          <div style="margin: 16px;">
            <van-button round block type="primary" native-type="submit">
              提交
            </van-button>
          </div>
        </van-form>
      </div>
    </van-popup>
  </div>
</template>

<script>
  import axios from 'axios'
  import { IndexBar, IndexAnchor, Image as VanImage, Badge, Popover, Popup, Tab, Tabs, Collapse, CollapseItem, NavBar, Uploader, RadioGroup, Radio, Calendar, Form, Picker } from 'vant';

  const formatDate = (date) => `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`

  export default {
    components: {
      [Form.name]: Form,
      [IndexBar.name]: IndexBar,
      [IndexAnchor.name]: IndexAnchor,
      [VanImage.name]: VanImage,
      [Badge.name]: Badge,
      [Popover.name]: Popover,
      [Popup.name]: Popup,
      [Tab.name]: Tab,
      [Tabs.name]: Tabs,
      [Collapse.name]: Collapse,
      [CollapseItem.name]: CollapseItem,
      [NavBar.name]: NavBar,
      [RadioGroup.name]: RadioGroup,
      [Radio.name]: Radio,
      [Calendar.name]: Calendar,
      [Picker.name]: Picker,
      [Uploader.name]: Uploader
    },
    name: 'AdminMemberDetails',
    data () {
      return {
        keyWord: '',
        user: {},
        actions: [
          { text: '会员详情' },
          { text: '添加项目' },
          { text: '绑定微信' },
        ],
        showAddRecord: false,
        showRecord: false,
        showPopover: false,
        show: false,
        showPicker: false,
        showServiceTime: false,
        showConsult: false,
        active: 0,
        activeNames: [],
        record: {
          service_situation: '',
          bf_situation: '',
          staff_name: '',
          staff_id: 0,
          service_time: '',
          next_time: '',
          autograph: '',
          status: '1',
          photo: [],
          remarks: '',
          frequency: '',
          is_subscribe: '否'
        },
        fileList: [],
        staffData: [],
        memberStore: {},
        projectList: [],
        project: {},
        picUrl: ''
      }
    },
    computed:{
      roleId(){
        return this.$store.state.roleId
      }
    },
    created () {
      this.user = this.$store.state;
      this.getMembersStore();
    },
    methods: {
      getStaff() {
        this.$api.get('rbac/users', {pageSize:1000, merchantId:this.memberStore.merchant_id}, r=>{
          this.staffData = r.result.data;
        })
      },
      getMembersStore() {
        this.$api.get('membersStore/'+this.$route.query.id, null, r=>{
          this.memberStore = r.result
          this.getStaff()
        })
      },
      onSearch () {
        console.log(this.keyWord)
      },
      showRecordList (record) {
        this.projectList = record
        this.showRecord = true
      },
      onConfirm (value) {
        this.record.staff_id = value.id
        this.record.staff_name = value.name
        this.showPicker = false
      },
      onServiceTime (value) {
        this.record.service_time = formatDate(value)
        this.showServiceTime = false
      },
      onClickLeft() {
        history.back();
      },
      addRecord (record) {
        this.project = record
        this.showAddRecord = true
      },
      onSubmit (values) {
        let self = this
        let serviceTime = values.service_time
        values.staff_id = self.record.staff_id
        values.members_store_id = this.memberStore.id
        values.members_stores_project_id = this.project.id
        values.service_time = serviceTime.split('/').join('-')
        values.photo.map(item=> {
          delete item.content
        })
        this.$api.post('membersStore/storeRecord', values, r=>{
          self.$toast({'message':r.message, onOpened:function(){
              if(r.status == 200){
                self.showAddRecord = false
                self.getMembersStore()
              }
            }})
        })
      },
      afterRead(file) {
        let self = this
        file.status = 'uploading';
        file.message = '上传中...';

        let url = process.env.VUE_APP_ADMIN_API_URL + '/images'
        let fd = new FormData()
        fd.append('file', file.file)
        axios.post(url, fd, {headers: {'Content-Type': 'multipart/form-data'}}).then(res => {
          file.status = 'done';
          file.message = '上传完成';
          delete self.fileList[this.fileList.length - 1].file
          self.fileList[this.fileList.length - 1].url = res.data.result.path;
          // self.record.photo = JSON.parse(JSON.stringify(self.fileList))
        }).catch(err => {
          alert(err)
        })
      },
      showPhoto(url) {
        this.picUrl = url
        this.showConsult = true
      }
    }
  }
</script>
<style scoped>
  .m-d{
    background: url(../../../assets/images/admin/bg_03.jpg) no-repeat #ffffff;
    background-size: 100%;
    overflow: hidden;
    padding:25px 10px 13px;
    margin-bottom: 10px;
  }
  .m-d .m-d-a{
    overflow: hidden;
  }
  .m-d .m-d-a .m-d-l{
    float: left;
    width: 17%;
    margin-left: 5px;
  }
  .m-d .m-d-a .m-d-l img{
    width: 100%;
  }
  .m-d .m-d-a .m-d-r{
    float: right;
    width: 78%;
    color:#666666;
  }
  .m-d .m-d-a .m-d-r h3{
    font-size: 14px;
    margin: 0;
  }
  .m-d .m-d-a .m-d-r p{
    font-size: 13px;
    margin: 0;
  }
  .m-d .m-d-a .m-d-r .m-d-r-m{
    line-height: 22px;
  }
  .m-d .m-d-a .m-d-r .m-d-r-m p span{
    margin: 0 20px;
  }
  .m-d .m-d-b{
    overflow: hidden;
  }
  .m-d .m-d-b .m-d-b-r{
    margin-top: 5px;
  }
  .project{
    overflow: hidden;
    background: #ffffff;
  }
  .project .p-tr{
    overflow: hidden;
    border-bottom: 1px solid #f4f4f4;
    padding:0 10px;
  }
  .project .p-tr .p-tr-m{
    overflow: hidden;
    border-bottom: 1px solid #f4f4f4;
    padding:10px 0;
  }
  .project .p-tr .p-tr-m .l-m{
    width: 80%;
    line-height: 24px;
    font-size: 12px;
  }
  .project .p-tr .p-tr-m .l-m .l-m-m{
    overflow: hidden;
  }
  .project .p-tr .p-tr-m .l-m .l-m-m .sp-1-l{
    width: 70%;
    text-align: left;
  }
  .project .p-tr .p-tr-m .l-m .l-m-m .sp-2-l{
    width: 30%;
    text-align: center;
  }
  .project .p-tr .p-tr-m .l-m .l-m-m .sp-2-l em{
    font-style: normal;
    color:#fe786a;
  }
  .project .p-tr span{
    display: inline-block;
    float: left;
  }
  .project .p-tr .p-hr-l{
    float: left;
    width: 80%;
    line-height: 24px;
  }
  .project .p-tr .p-hr-l .sp-1{
    width: 70%;
  }
  .project .p-tr .p-hr-l .sp-2{
    width: 30%;
    text-align: center;
  }
  .project .p-tr .p-hr-l span{
    display: inline-block;
  }
  .project .p-tr .p-hr-r{
    float: right;
  }
  .project .p-tr .sp-3{
    text-align: right;
    float: right;
  }
  .project .p-hr{
    overflow: hidden;
    font-weight: bold;
    padding:5px 10px;
    font-size: 13px;
  }
  .orders{
    overflow: hidden;
    background: #ffffff;
    padding:0 10px;
  }
  .orders .o-li{
    overflow: hidden;
  }
  .orders .o-li span{
    display: inline-block;
  }
  .orders .o-li .o-li-th{
    overflow: hidden;
    font-weight: bold;
    font-size: 13px;
    border-bottom: 1px solid #f4f4f4;
    padding:5px 0;
  }
  .orders .o-li .o-li-th .sp-1{
    width: 40%;
  }
  .orders .o-li .o-li-th .sp-2{
    width: 40%;
  }
  .orders .o-li .o-li-th .sp-3{
    width: 20%;
  }
  .orders .o-li .o-li-tr{
    color:#999999;
    padding:10px 0;
    border-bottom: 1px solid #f4f4f4;
  }
  .orders .o-li .o-li-tr .sp-1{
    width: 40%;
  }
  .orders .o-li .o-li-tr .sp-2{
    width: 40%;
  }
  .orders .o-li .o-li-tr .sp-3{
    width: 20%;
  }
  .team{
    overflow: hidden;
    background: #ffffff;
    padding:0 10px;
  }
  .team span{
    display: inline-block;
    line-height: 24px;
  }
  .team .sp-1{
    width: 25%;
    float: left;
  }
  .team .sp-1 .img{
    float: left;
    height: 35px;
  }
  .team .sp-2{
    width: 50%;
  }
  .team .sp-3{
    width: 25%;
  }
  .team .th{
    overflow: hidden;
    font-weight: bold;
    font-size: 13px;
    border-bottom: 1px solid #f4f4f4;
    padding:5px 0;
  }
  .team .tr{
    color:#999999;
    padding:10px 0;
    border-bottom: 1px solid #f4f4f4;
    overflow: hidden;
  }
  .commission{
    overflow: hidden;
    background: #ffffff;
    padding:0 10px;
  }
  .commission span{
    display: inline-block;
  }
  .commission .sp-1{
    width: 55%;
  }
  .commission .sp-2{
    width: 20%;
  }
  .commission .sp-3{
    width: 25%;
  }
  .commission .th{
    overflow: hidden;
    font-weight: bold;
    font-size: 13px;
    border-bottom: 1px solid #f4f4f4;
    padding:5px 0;
  }
  .commission .tr{
    color:#999999;
    padding:10px 0;
    border-bottom: 1px solid #f4f4f4;
  }
  .red{
    font-style: normal;
    color:#fe786a;
  }
  .record-m{
    padding:10px;
    margin-top: 10px;
    overflow: hidden;
  }
  .record-m .r-m-li{
    overflow: hidden;
    border-bottom: 1px solid #f4f4f4;
    padding-bottom: 5px;
    margin-top: 10px;
  }
  .record-m .r-m-li .r-m-l{
    float: left;
    width: 8%;
    text-align: center;
  }
  .record-m .r-m-li .r-m-l .sp-1{
    height: 20px;
    line-height: 20px;
    border-bottom:2px solid #1989fa;
    display: inline-block;
  }
  .record-m .r-m-li .r-m-r{
    float: right;
    width: 90%;
  }
  .record-m .r-m-li .r-m-r .r-m-t-1{
    overflow: hidden;
  }
  .record-m .r-m-li .r-m-r .r-m-t-1 .r-l{
    margin-right: 60px;
  }
  .record-m .r-m-li .r-m-r .r-m-t-2{
    overflow: hidden;
    margin: 5px 0;
    color:#666666;
  }
  .record-m .r-m-li .r-m-r .r-m-t-3{

  }
  .blue{
    color:#1989fa;
  }
  .consult_pic{
    overflow: hidden;
  }
  .consult_pic img{
    width: 310px;
  }
  .pic-title{
    float: left;
  }
  .pic-li{
    width: 30px;
    height: 30px;
    line-height: 30px;
    margin-right: 8px;
    border:2px solid #1989fa;
  }
</style>
