<template>
  <div>
    <van-nav-bar
        title="推广海报"
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
    />
    <van-dropdown-menu active-color="#1989fa">
      <van-dropdown-item v-model="cid" :options="cates" @change="onSearch($event, 1)" />
      <van-dropdown-item v-model="sort" :options="sorts" @change="onSearch($event, 2)" />
    </van-dropdown-menu>
    <div class="p-list">
      <ul>
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
          <van-list
              v-model:loading="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onLoad"
          >
            <li v-for="item in list" :key="item.id" :title="item.name" @click="showPopup(item)">
              <div class="p-l-d"><img :src="item.thumbnail" /></div>
              <span>{{item.dict.name}}</span>
            </li>
          </van-list>
        </van-pull-refresh>
      </ul>
    </div>
    <van-popup v-model:show="show">
      <div class="popup-main">
        <div class="cp-brief">
          <h3>文案内容</h3>
          <div v-html="previewContent"></div>
        </div>
        <img alt="example" style="width: 100%" :src="previewImage" />
        <div class="p-msg">长按图片下载海报</div>
      </div>
    </van-popup>
    <AdminFooter />
    <WxNoShare />
  </div>
</template>

<script>
  import { Grid, GridItem, NavBar, DropdownMenu, DropdownItem, List, PullRefresh, Popup } from 'vant';

  export default {
    components: {
      [Grid.name]: Grid,
      [GridItem.name]: GridItem,
      [NavBar.name]: NavBar,
      [DropdownMenu.name]: DropdownMenu,
      [DropdownItem.name]: DropdownItem,
      [List.name]: List,
      [PullRefresh.name]: PullRefresh,
      [Popup.name]: Popup,
    },
    name: 'AdminManagePoster',
    data () {
      return {
        show: false,
        user: {},
        cates: [],
        cateTag: [],
        sortField: 'id',
        sorts: [
          { text: '默认排序', value: 'id' },
          { text: '好评排序', value: 'view_count' },
        ],
        cid: -1,
        sort: 'id',
        loading: false,
        finished: false,
        refreshing: false,
        list: [],
        page: 1,
        lastPage: 0,
        previewContent: '',
        previewImage: ''
      }
    },
    created () {
      this.getCate()
    },
    methods: {
      getCate() {
        this.$api.get('dicts/cate', {pid:83, pageSize:40}, r=>{
          r.result.map(item=>{
            item.value = item.id
            item.text = item.name
          })
          let art = r.result
          art.unshift({value:-1, text:'全部海报'})
          this.cates = art
        })
      },
      onLoad () {
        this.$api.get('designs', {pid:83, pageSize: 30, page: this.page, cateTag: this.cateTag, sortField: this.sortField}, r=>{
          this.list.push(...r.result.data)
          this.loading = false
          if (this.page >= r.result.last_page) {
            this.finished = true
          }
          this.page = this.page + 1
        })
      },
      onRefresh () {
        this.page = 1
        this.list = []
        // 清空列表数据
        this.finished = false;

        // 重新加载数据
        // 将 loading 设置为 true，表示处于加载状态
        this.loading = true;
        this.onLoad();
      },
      onSearch (value, type) {
        this.page = 1
        this.list = []
        this.cateTag = []
        if(type == 1){
          if(this.cateTag[0] == -1){
            this.cateTag = []
          }else{
            this.cateTag.push(value)
          }
        }else if(type == 2){
          this.sortField = value
        }
        this.onLoad()
      },
      showPopup (record) {
        this.previewContent = record.content
        this.previewImage= record.thumbnail
        this.show = true
      },
      onClickLeft() {
        history.back()
      }
    }
  }
</script>
<style scoped>
  .p-list{
    overflow: hidden;
  }
  .p-list ul li{
    float: left;
    width: 48%;
    margin: 1%;
    background: #ffffff;
  }
  .p-list ul li .p-l-d{
    max-height: 200px;
    overflow: hidden;
  }
  .p-list ul li img{
    width: 100%;
  }
  .p-list ul li span{
    display: inline-block;
    padding:10px 5px;
  }
  .popup-main{
    padding:10px;
    overflow: hidden;
  }
  .popup-main .cp-brief h3{
    margin: 0;
  }
  >>> .van-popup--center{
    width: 80%;
  }
  .popup-main .p-msg{
    text-align: center;
    font-size: 12px;
    color:#939497;
  }
</style>
