/* eslint-disable */

export default {
  // 日期格式化 fmt 格式化字符如 'yyyy-MM-dd hh:mm:ss' v 可以是日期或字符串
  datetimeFormat: function(v, fmt) {
    if ((typeof v).toLocaleLowerCase() == 'string') { v = new Date(v.replace(/-/g, '/')) }
    var o = {
      'M+': v.getMonth() + 1, // 月份
      'd+': v.getDate(), // 日
      'h+': v.getHours(), // 小时
      'm+': v.getMinutes(), // 分
      's+': v.getSeconds(), // 秒
      'q+': Math.floor((v.getMonth() + 3) / 3), // 季度
      'S': v.getMilliseconds() // 毫秒
    }
    if (/(y+)/.test(fmt)) { fmt = fmt.replace(RegExp.$1, (v.getFullYear() + '').substr(4 - RegExp.$1.length)) }
    for (var k in o) {
      if (new RegExp('(' + k + ')').test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
      }
    }
    return fmt
  },

  // 获取日期的星期 v=日期字符串或日期对象 返回值 0-6对应 周日-周六
  getWeek: function(v) {
    if ((typeof v).toLocaleLowerCase() == 'string') { v = new Date(v.replace(/-/g, '/')) }
    var end = v.getDay()
    return end
  },

  // 字符串转日期 v必须为 -格式 如 2020-05
  toDateTime: function(v) {
    if ((typeof v).toLocaleLowerCase() == 'string') { v = new Date(v.replace(/-/g, '/')) }
    return v
  },

  // 用于将数组进行name匹配 这里用作export2excel.js插件的智能字段识别导出
  formatJson: function(filterVal, jsonData) {
    return jsonData.map(v => filterVal.map(j => v[j]))
  },

  /**
   * 手机号码验证
   * @param {Object} phone 手机号码
   */
  verifyPhone: function(phone) {
    var phoneReg = /^0?1[3|4|5|8|7|9|6][0-9]\d{8}$/;
    var flag = phoneReg.test(phone);
    if (!flag) {
      return false;
    }
    return true;
  },

  /**
   * 加密手机号码
   * @param {Object} param 需要加密手机号码
   */
  encryptPhone: function(param) {
    var param = param.toString();
    return param.substring(0, 3) +'****'+ param.substring(7, 11);
  },

  /**
   * 去字符串中所有空格
   * @param {Object} str 需要去空格的字符串
   */
  deleteSpaceAll: function(str) {
    str = str.toString();
    return str.replace(/\s/g, "");
  },

  /**
   * 去字符串中左右两边的空格
   * @param {Object} str 需要去空格的字符串
   */
  deleteSpaceLR: function(str) {
    return str.replace(/(^\s*)|(\s*$)/g, '');
  },

  /**
   * 判断是否为空
   * @param {Object} str
   */
  isNull: function(str = '') {
    if(typeof (str) == 'number') {
      str = str.toString();
    }
    if(str.replace(/(^s*)|(s*$)/g, "").length == 0) {
      return true;
    }
    return false;
  },

  /**
   * 解析url参数并获取code
   * @param name
   * @returns {string|null}
   */
  getUrlParam(name) {
    //name为要获取的参数名
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    var rrr = decodeURIComponent(window.location.search);
    var r = rrr.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
  }

}
