<template>
  <div>
    <router-view></router-view>
    <AdminFooter />
    <WxNoShare />
  </div>
</template>

<script>
  export default {
    components: {
    },
    name: 'AdminIndex',
  }
</script>
<style scoped>

</style>
