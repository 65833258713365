<template>
  <div>
    <router-link to="/users/resetPwd">
      <van-cell title="修改密码" is-link url="" />
    </router-link>

    <Floating toUrl="/users/index" />
    <wxNoShare />
  </div>
</template>

<script>
  import { Cell, CellGroup } from 'vant';

  export default {
    components: {
      [Cell.name]: Cell,
      [CellGroup.name]: CellGroup
    },
    name: 'security'
  }
</script>

<style scoped>

</style>
