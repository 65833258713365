import { createApp } from 'vue'
import App from './App.vue'
import { Button, Tabbar, TabbarItem, Toast, Col, Row, Field, Icon, Lazyload } from 'vant'
import 'vant/lib/index.css'
import router from './router'
import 'lib-flexible/flexible'
import store from './store'
import './assets/css/style.css'
import './assets/iconfont/iconfont.js'
import api from './api'
import axios from 'axios'
import common from './common'
import Footer from '@/components/common/footer.vue'
import AdminFooter from '@/components/common/adminFooter.vue'
import Floating from '@/components/common/floating.vue'
import WxNoShare from '@/components/wxjsapi/noShare.vue'

const app = createApp(App).use(router).use(store); // 创建实例

app.config.globalProperties.$api = api;
app.config.globalProperties.common = common;
app.use(Button);
app.use(Tabbar);
app.use(TabbarItem);
app.use(Toast);
app.use(Col);
app.use(Row);
app.use(Field);
app.use(Icon);
app.use(Lazyload);
app.use(router);

// 全局自定义组件
app.component("Footer", Footer);
app.component("AdminFooter", AdminFooter);
app.component("Floating", Floating);
app.component("WxNoShare", WxNoShare);

app.mount('#app');

// createApp(App).mount('#app')

// 使用router.beforeEach注册一个全局前置守卫,判断用户是否登录
router.beforeEach((to, from, next) => {
    let redirectUrl = process.env.VUE_APP_M_ACCOUNT_URL + to.href;
    let code = getUrlParam("code");
    let mid = getUrlParam("mid");
    let hid = getUrlParam("hid");
    if(mid != '' && mid != null) {
        sessionStorage.setItem('mid', mid);
    }
    if(to.meta.isAdmin){
        // B端商户登录
        if (to.meta.requireAuth) {
            if (store.state.adminAuth) {
                next();
            } else {
                if(process.env.VUE_APP_NODE_ENV === 'production') {
                    // 微信授权登录
                    if(!code){
                        sessionStorage.setItem('redirectPath', redirectUrl);
                        window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + process.env.VUE_APP_WX_APPID + "&redirect_uri=" + redirectUrl + "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
                    }
                    let authUrl = process.env.VUE_APP_ADMIN_API_URL + '/socials/wechat/authorizations?code=' + code
                    axios.post(authUrl).then(function (res) {
                        let data = res.data
                        if (data.status == 200) {
                            store.dispatch('logined', data.result);
                            // 重定向页面
                            let redirectPath = sessionStorage.getItem('redirectPath');
                            if (redirectPath) {
                                sessionStorage.removeItem('redirectPath');
                                window.location.href = redirectPath;
                            } else {
                                next()
                            }
                        }else{
                            window.location.href = '/admin/empty';
                        }
                    }).catch(function (error) {
                        console.log(error);
                    });
                }else if(process.env.VUE_APP_NODE_ENV === 'development'){
                    // 开发测试
                    axios.get(process.env.VUE_APP_API_URL + '/authorizations/testLogin?type=1').then(function (res) {
                        let data = res.data;
                        if (data.status === 200) {
                            store.dispatch('logined', data.result);
                            // 重定向页面
                            let redirectPath = sessionStorage.getItem('redirectPath');
                            if (redirectPath) {
                                sessionStorage.removeItem('redirectPath');
                                //window.location.href = redirectPath;
                            } else {
                                next()
                            }
                        }else{
                            window.location.href = '/admin/empty';
                        }
                    }).catch(function (error) {
                        console.log(error);
                    });
                }
            }
        } else {
            next();
        }
    }else{
        // alert('session_mid：'+sessionStorage.getItem('hid'));
        // C端用户登录
        if (to.meta.requireAuth) {
            if (store.state.auth) {
                next();
            } else {
                if(process.env.VUE_APP_NODE_ENV === 'production') {
                    // 微信授权登录
                    if(!code){
                        sessionStorage.setItem('redirectPath', redirectUrl);
                        window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + process.env.VUE_APP_WX_APPID + "&redirect_uri=" + redirectUrl + "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
                    }
                    let authUrl = process.env.VUE_APP_API_URL + '/socials/wechat/authorizations?code=' + code + '&mid=' + sessionStorage.getItem('mid')
                    axios.post(authUrl).then(function (res) {
                        let data = res.data
                        if (data.status === 200) {
                            store.dispatch('logined', data.data);
                            // 重定向页面
                            let redirectPath = sessionStorage.getItem('redirectPath');
                            if (redirectPath) {
                                sessionStorage.removeItem('redirectPath');
                                window.location.href = redirectPath;
                            } else {
                                next()
                            }
                        }
                    }).catch(function (error) {
                        console.log(error);
                    });
                }else if(process.env.VUE_APP_NODE_ENV === 'development'){
                    // 开发测试
                    axios.get(process.env.VUE_APP_API_URL + '/authorizations/testLogin?hid=' + hid).then(function (res) {
                        let data = res.data;
                        if (data.status === 200) {
                            store.dispatch('logined', data.data);
                            // 重定向页面
                            let redirectPath = sessionStorage.getItem('redirectPath');
                            if (redirectPath) {
                                sessionStorage.removeItem('redirectPath');
                                window.location.href = redirectPath;
                            } else {
                                next()
                            }
                        }
                    }).catch(function (error) {
                        console.log(error);
                    });
                }
            }
        } else {
            next();
        }
    }
});

router.afterEach((to) => {
    document.title = to.meta.title || ""
})

// 解析url参数并获取code
function getUrlParam(name) {
    //name为要获取的参数名
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    var rrr = decodeURIComponent(window.location.search);
    var r = rrr.substr(1).match(reg);
    if (r != null) return unescape(r[2]);
    return null;
}
