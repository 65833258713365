<template>
  <div>
    <van-nav-bar
        title="门店设置"
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
    />
    <van-form @submit="onSubmit" class="c-e-form">
      <van-field
              v-model="name"
              name="name"
              label="门店名称"
              placeholder="请填写门店名称"
              :rules="[{ required: true, message: '请填写门店名称' }]"
      />
      <van-field
              v-model="brief"
              rows="5"
              autosize
              label="门店简介"
              type="textarea"
              name="brief"
              maxlength="500"
              placeholder="请输入简介"
              show-word-limit
      />
      <van-field
              v-model="hotline"
              name="hotline"
              label="门店电话"
              placeholder="请填写门店电话"
              :rules="[{ required: true, message: '请填写门店电话' }]"
      />
      <van-field
              v-model="address"
              rows="3"
              autosize
              label="门店地址"
              type="textarea"
              name="address"
              maxlength="300"
              placeholder="请输入地址"
              show-word-limit
      />
      <div style="margin: 16px;">
        <van-button round block type="primary" native-type="submit">
          提交
        </van-button>
      </div>
    </van-form>
    <AdminFooter />
    <WxNoShare />
  </div>
</template>

<script>
  import { NavBar,Popup,Form, Field, Cell, CellGroup, RadioGroup, Radio } from 'vant';

  export default {
    components: {
      [NavBar.name]: NavBar,
      [Popup.name]: Popup,
      [Form.name]: Form,
      [Field.name]: Field,
      [Cell.name]: Cell,
      [CellGroup.name]: CellGroup,
      [RadioGroup.name]: RadioGroup,
      [Radio.name]: Radio,
    },
    name: 'AdminManageStore',
    data () {
      return {
        show: false,
        user: {},
        name: '',
        brief: '',
        hotline: '',
        address: '',
        content: '',
      }
    },
    created () {
      this.getStore()
    },
    methods: {
      getStore() {
        this.$api.get('stores/show', null, r=>{
          this.name = r.result.name
          this.brief = r.result.brief
          this.hotline = r.result.hotline
          this.address = r.result.address
          this.content = r.result.content
        })
      },
      onSubmit (values) {
        this.$api.post('stores/update', values, r=>{
          if(r.status === 200) {
            this.$toast('保存成功')
          }
        })
      },
      onClickLeft() {
        history.back();
      }
    }
  }
</script>
<style scoped>

</style>
