<template>
  <div>
    <div class="f-h"></div>
    <div class="footer">
      <van-tabbar v-model="active">
        <van-tabbar-item icon="balance-o" @click="toBarUrl('AdminStatistics', 0)">业绩</van-tabbar-item>
        <van-tabbar-item icon="cluster-o" @click="toBarUrl('AdminManage', 1)">管理</van-tabbar-item>
        <van-tabbar-item icon="friends-o" @click="toBarUrl('AdminMember', 2)">会员</van-tabbar-item>
        <van-tabbar-item icon="todo-list-o" @click="toBarUrl('AdminSubscribe', 3)">预约</van-tabbar-item>
        <van-tabbar-item icon="manager-o" @click="toBarUrl('AdminUser', 4)">我的</van-tabbar-item>
      </van-tabbar>
    </div>
  </div>
</template>

<script>
    export default {
      data () {
        return {
          active: 0
        }
      },
      created () {
        let routeName = this.$route.name
        if (routeName == 'AdminStatistics') {
          this.active = 0
        }else if (routeName == 'AdminManage' || routeName == 'AdminManagePoster') {
          this.active = 1
        }else if (routeName == 'AdminMember' || routeName == 'AdminMemberDetails') {
          this.active = 2
        }else if (routeName == 'AdminSubscribe') {
          this.active = 3
        }else if (routeName == 'AdminUser' || routeName == 'AdminUserRecord') {
          this.active = 4
        }
      },
      methods: {
        toBarUrl (name, type) {
            this.active = type
            this.$router.push({name: name})
          }
      }
    }
</script>

<style scoped>

</style>
