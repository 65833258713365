<template>
  <div></div>
</template>

<script>
  import wx from 'weixin-jsapi'

  export default {
    name: 'noShare',
    created () {
      this.getShare()
    },
    methods: {
      getShare(){
        wx.hideOptionMenu();
        wx.hideMenuItems({
          menuList: []
        });
      }
    }
  }
</script>

<style scoped>

</style>
