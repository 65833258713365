<template>
    <div>
        <van-collapse v-model="activeNames">
            <van-collapse-item :title="project.stores_project_info.name" :name="index+1" v-for="(project, index) in projects" :key="project.id">
                <div class="records">
                    <van-row>
                        <van-col span="3">次数</van-col>
                        <van-col span="8">脱发情况</van-col>
                        <van-col span="7">白发情况</van-col>
                        <van-col span="6">服务日期</van-col>
                    </van-row>
                    <van-row v-for="p in project.records" :key="p.id+`a`">
                        <van-col span="3">{{p.frequency}}</van-col>
                        <van-col span="8">{{p.service_situation}}</van-col>
                        <van-col span="7">{{p.bf_situation}}</van-col>
                        <van-col span="6"><div class="van-ellipsis">{{p.sever_time}}</div></van-col>
                    </van-row>
                </div>
            </van-collapse-item>
        </van-collapse>

        <Floating skipUrl="/user/index" />
        <wxNoShare />
    </div>
</template>

<script>
    import { Collapse, CollapseItem } from 'vant'

    export default {
        components: {
            [Collapse.name]: Collapse,
            [CollapseItem.name]: CollapseItem
        },
        name: 'usersProject',
        data() {
            return {
                activeNames: [],
                projects: []
            }
        },
        created () {
            this.getProject()
        },
        methods: {
            getProject() {
                this.$api.get('storesMembers/project', {mid: sessionStorage.getItem('mid')}, r=>{
                    this.projects = r.data;
                })
            }
        }
    }
</script>

<style scoped>

</style>
