<template>
    <div>
        <van-search v-model="keyword" placeholder="请输入搜索关键词" />
        <van-tree-select
            v-model:main-active-index="activeIndex"
            height="auto"
            :items="projects"
        >
            <template #content>
                <div class="p-li" v-for="(project, index) in projects" :key="project.id" v-show="activeIndex == index">
                    <van-row class="p-li-m" v-for="p in project.list" :key="p.id" @click="goDetails(p)">
                        <van-col span="10" class="img"><img v-if="p.image" :src="p.image" /><img v-else src="https://img.yangfayun.com/yfy/def_logo.jpg?x-oss-process=style/w380" /></van-col>
                        <van-col span="14" class="p-info">
                            <h3>{{ p.name }}</h3>
                            <span>已售{{ p.sales + p.sales_initial }}</span>
                        </van-col>
                    </van-row>
                </div>
            </template>
        </van-tree-select>

        <Footer />
        <WxNoShare />
    </div>
</template>

<script>
    import { TreeSelect, Search } from 'vant';

    export default {
        components: {
            [TreeSelect.name]: TreeSelect,
            [Search.name]: Search
        },
        name: "project",
        data() {
            return {
                keyword: '',
                activeIndex: 0,
                projects: []
            }
        },
        created () {
            this.getProject();
            if(this.$route.query.active != '' && this.$route.query.active != undefined){
                this.activeIndex = this.$route.query.active
            }else{
                this.activeIndex = 0
            }
        },
        methods: {
            getProject() {
                this.$api.get('storeProject/index', {mid: sessionStorage.getItem('mid')}, r=>{
                    r.data.map(item =>{
                        item.text = item.name
                    });
                    this.projects = r.data;
                })
            },
            goDetails(r) {
                this.$router.push({name: 'projectDetails', query: {id: r.id, mid: sessionStorage.getItem('mid') }})
            }
        }
    }
</script>

<style scoped>
    .p-li{
        overflow: hidden;
        padding: 0 5px;
    }
    .p-li .p-li-m{
        margin-bottom: 15px;
    }
    .p-li .img{
        float: left;
        width:100px;
        height: 80px;
    }
    .p-li .img img{
        width: 100%;
        height: 80px;
    }
    .p-li .p-info{
        overflow: hidden;
        padding-left: 5px;
        position: relative;
    }
    .p-li .p-info h3{
        margin: 0;
        font-size: 14px;
        font-weight: normal;
    }
    .p-li .p-info span{
        display: block;
        position: absolute;
        left: 5px;
        bottom: 0;
        font-size: 12px;
        color:#A2A1A3;
    }
</style>
