<template>
    <div v-show="show">
        <div class="banner">
            <img :src="details.image" />
        </div>
        <div class="d-info">
            <h3>{{details.title}}</h3>
            <div class="price">
            <!--<span class="p-l">￥{{details.price}} <em v-if="details.line_price != null">￥{{details.line_price}}</em></span>-->
                <span class="left s-s-r"><em>{{details.sales + details.sales_initial}}</em>人报名</span>
                <span class="time" v-if="details.pay_status == 1"><van-count-down :time="details.time" format="还剩DD天HH:mm:ss" /></span>
            </div>
            <!--
            <div class="see-show">
                <span class="left"><em>{{details.sales + details.sales_initial}}</em>人报名</span>
                <div class="right s-s-r">
                    <span><em>{{details.view_count}}</em>人查看</span>
                </div>
            </div>
            -->
        </div>
        <div class="d-content">
            <!--<span class="title">活动详情</span>-->
            <div class="d-c-tab">
                <van-tabs v-model:active="active" @click-tab="onClickTab" type="card">
<!--                    <van-tab title="会议详情"><div class="content" v-html="details.content"></div></van-tab>-->
                    <van-tab title="会议地址"><div class="content"><img src="https://img.yangfayun.com/yfy/1.jpg" /></div></van-tab>
                </van-tabs>
            </div>
        </div>
        <van-action-bar>
            <!--<van-action-bar-button type="info" text="分享海报" @click="onClickPoster" />-->
            <van-action-bar-button type="danger" text="立即报名" @click="addEdit()" v-if="details.pay_status == 1 && apply_status == 0" />
            <van-action-bar-button type="danger" text="审核中" @click="addEdit()" v-else-if="details.pay_status == 1 && apply_status == 1" />
            <van-action-bar-button type="danger" text="报名成功" v-else-if="details.pay_status == 1 && apply_status == 2" />
            <van-action-bar-button type="danger" text="活动未开始" v-else-if="details.pay_status == 2" />
            <van-action-bar-button type="danger" text="活动已结束" v-else-if="details.close_status == 1" />
        </van-action-bar>
<!--        <div class="user-suspension" @click="toUrl('user')"><van-icon name="user-o" class="user" /></div>-->

        <van-dialog v-model:show="showMessage" title="报名信息" show-cancel-button :before-close="beforeClose">
            <van-field
                    v-model="real_name"
                    name="真实姓名"
                    label="真实姓名"
                    placeholder="请输入真实姓名"
                    :rules="[{ required: true, message: '请填写真实姓名' }]"
            />
            <van-field
                    v-model="phone"
                    type="tel"
                    label="手机号"
                    placeholder="请输入手机号"
                    maxlength="11"
                    :rules="[{ required: true, message: '请填写手机号' }]"
            />
            <div class="d-code">
                <van-field
                        v-model="code"
                        maxlength="6"
                        autocomplete="off"
                        center
                        clearable
                        label="短信验证码"
                        placeholder="请输入验证码"
                >
                    <template #button>
                        <van-button size="small" type="primary" v-if="count>=90" @click="getCode" native-type="button">发送验证码</van-button>
                        <van-button size="small" type="primary" disabled native-type="button" v-else>倒计时 {{count}} s</van-button>
                    </template>
                </van-field>
            </div>
            <p class="p-msg">提示：报名成功后，公司客服会与您联系确认，请保持保持手机畅通</p>
        </van-dialog>
        <!--音乐、特效-->
        <!--
        <div class="m-mp3">
            <img src="../../assets/images/mp3_1.png" class="m-mp3-img Rotation" @click="musicPay" id="act-mp3" />
            <audio src="https://img.yangfayun.com/yfy/14.mp3" id="music" controls="controls" loop="loop" autoplay="autoplay"></audio>
        </div>
        -->
    </div>
</template>

<script>
    import { ActionBar, ActionBarIcon, ActionBarButton, CountDown, Tab, Tabs, Popup, Dialog, Cell, CellGroup } from 'vant';
    import wx from 'weixin-jsapi';
    export default {
        components: {
            [ActionBar.name]: ActionBar,
            [ActionBarButton.name]: ActionBarButton,
            [ActionBarIcon.name]: ActionBarIcon,
            [CountDown.name]: CountDown,
            [Tab.name]: Tab,
            [Tabs.name]: Tabs,
            [Dialog.Component.name]: Dialog.Component,
            [Popup.name]: Popup,
            [Cell.name]: Cell,
            [CellGroup.name]: CellGroup
        },
        data() {
            return {
                show: false,
                showMessage: false,
                isMusicPay: 0,
                id: 0,
                details: {},
                shareLink: '',
                active: 0,
                count: 90,
                timer: null,
                real_name: '',
                phone: '',
                verification_key: '',
                code: '',
                apply_status: 0
            }
        },
        created () {
            this.id = this.$route.query.id;
            this.getDetails();
            this.shareLink = window.location.href;
            this.getJssdkConfig("onMenuShareTimeline,checkJsApi,onMenuShareAppMessage", false, false, this.shareLink);
            this.getYearDetails();
        },
        methods: {
            toUrl (name) {
                this.$router.push({name: name})
            },
            getYearDetails() {
                this.$api.get('year/details', null, r=>{
                    this.apply_status = r.data.apply_status;
                    if(this.apply_status == 0) {
                        this.showMessage = true
                    }
                })
            },
            getDetails() {
                const regex = new RegExp('<img', 'gi');
                this.$api.get('activity/details', {id: this.id}, r=>{
                    this.details = r.data;
                    let content = this.details.content;
                    if (this.details.content != null) {
                        this.details.content = content.replace(regex, `<img style="max-width: 100%; height: auto"`);
                    }
                    if(this.details.time > 0){
                        this.details.time = this.details.time * 1000;
                    }
                    sessionStorage.setItem('mid', this.details.mid);
                    this.show = true;
                })
            },
            onClickPoster() {
                this.$toast('分享海报')
            },
            onClickTab(e) {
                this.$toast(e)
            },
            addEdit() {
                if(this.details.pay_status == 1 && this.apply_status == 1){
                    this.$toast('您的报名正在审核中');
                }else{
                    this.showMessage = true;
                }
            },
            //获取验证码
            getCode() {
                if (!this.phone.length) {
                    this.$toast('请输入手机号');
                } else if (!this.common.verifyPhone(this.phone)) {
                    // !/^((1[3,5,8,7,9][0-9])|(14[5,7])|(17[0,6,7,8])|(19[1,7]))\d{8}$/.test(this.phone)
                    this.$toast('请输入正确的手机号码');
                    return
                } else {
                    // 发送验证码
                    this.$api.post('verificationCodes', {phone:this.phone, sign_type:1, tpl_type:1}, r=>{
                        if(r.status === 200){
                            this.$toast(r.message)
                            this.verification_key = r.data.key
                            this.timer = setInterval(()=>{
                                this.count --
                                if(this.count<=0){
                                    clearInterval(this.timer);
                                    this.count = 90;
                                }
                            },1000)
                        }
                    })
                }
            },
            beforeClose : function (action) {
                new Promise((resolve) =>{
                    if (action === 'confirm') {
                        if(this.real_name == ''){
                            this.$toast('请输入真实姓名');
                            resolve(false);
                            return false;
                        }
                        if(this.phone == ''){
                            this.$toast('请输入手机号');
                            resolve(false);
                            return false;
                        }
                        if(this.code.trim() == ''){
                            this.$toast('短信验证码不能为空');
                            resolve(false);
                            return false;
                        }
                        // 添加
                        this.$api.post('year/store', {real_name:this.real_name, phone:this.phone, code: this.code, verification_key: this.verification_key}, r=>{
                            this.$toast({'message':r.message, onOpened:function(){
                                if(r.status === 200){
                                    window.location.reload()
                                }
                            }})
                        });
                        resolve(true);
                    } else if (action === 'cancel') { // 取消
                        this.showMessage =  false;
                        this.real_name = '';
                        this.phone = '';
                        resolve(false);
                    }
                });
            },
            getJssdkConfig(apis,debug,json,link){
                let self = this;
                this.$api.get('jssdkConfig', {apis:apis, debug:debug, json:json, url: link}, r=>{
                    let config = r.data;
                    wx.config(config);
                    wx.ready(function() {
                        wx.showOptionMenu();
                        let shareData = {
                            title: self.details.share_title,
                            desc: self.details.share_brief,
                            link: self.shareLink,
                            imgUrl: self.details.image,
                            success: function () {
                                self.$api.post('activity/share', {id: self.details.id})
                            }
                        };
                        wx.onMenuShareAppMessage(shareData);
                        wx.onMenuShareTimeline(shareData);
                    })
                })
            },
            musicPay() {
                if(this.isMusicPay == 0){
                    this.isMusicPay = 1
                    document.getElementById('act-mp3').className='m-mp3-img';
                    document.getElementById('music').pause();
                }else{
                    this.isMusicPay = 0
                    document.getElementById('act-mp3').className='m-mp3-img Rotation';
                    document.getElementById('music').play();
                }
            }
        }
    }
</script>

<style scoped>
    .banner{
        overflow: hidden;
    }
    .banner img{
        width: 100%;
    }
    .d-info{
        overflow: hidden;
        padding:10px;
        background: #ffffff;
    }
    .d-info h3{
        margin: 0;
        font-size: 15px;
    }
    .d-info .price{
        overflow: hidden;
        line-height: 30px;
        margin-top: 5px;
    }
    .d-info .price .p-l{
        float: left;
        font-size: 18px;
        color:#EE0A24;
        font-weight: bold;
    }
    .d-info .price .p-l em{
        font-style: normal;
        font-size: 13px;
        font-weight: normal;
        color:#A2A1A3;
        text-decoration: line-through;
        margin-left: 2px;
    }
    .d-info .price .time{
        float: right;
        font-size: 14px;
    }
    .d-info .see-show{
        overflow: hidden;
    }
    .d-info .see-show em{
        color:#EE0A24;
        font-style: normal;
    }
    .s-s-r em{
        color:#ee0a24;
        font-style: normal;
    }
    .d-info .see-show .s-s-r .sp-2{
        margin-left: 10px;
    }
    .d-content{
        overflow: hidden;
        background: #ffffff;
        padding-bottom:10px;
        margin-top:10px;
    }
    .d-content .title{
        display: block;
        text-align: left;
        font-size: 15px;
        font-weight: bold;
        padding:10px;
        color:#393939;
    }
    .d-content .content{
        padding:15px 0;
    }
    .d-content .content img{
        width: 100%;
    }
    .d-c-tab{
        margin-top: 10px;
    }
    .d-code{
        overflow: hidden;
    }
    .p-msg{
        padding:0 15px;
        font-size: 12px;
        color:#a19d9d;
    }
    .m-mp3{position: fixed;top:2%;right:5%;z-index: 9;width: 30px;text-align: center;}
    .m-mp3 .m-mp3-img{display: inline-block;margin-bottom: 3px;width: 40px;}
    .m-mp3 audio{display: none;}
    @-webkit-keyframes rotation{
        from {-webkit-transform: rotate(0deg);}
        to {-webkit-transform: rotate(360deg);}
    }
    .Rotation{
        -webkit-transform: rotate(360deg);
        animation: rotation 3s linear infinite;
        -moz-animation: rotation 3s linear infinite;
        -webkit-animation: rotation 3s linear infinite;
        -o-animation: rotation 3s linear infinite;
    }
    .snowfall-flakes{animation:sakura 1s linear 0s infinite;}
    @keyframes sakura{
        0% {transform:rotate3d(0, 0, 0, 0deg);}
        25%{transform:rotate3d(1, 1, 0, 60deg);}
        50%{transform:rotate3d(1, 1, 0, 0deg);}
        75%{transform:rotate3d(1, 0, 0, 60deg);}
        100% {transform:rotate3d(1, 0, 0, 0deg);}
    }
</style>
