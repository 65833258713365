/* eslint-disable */
import Vuex from 'vuex'
import axios from 'axios'
import createPersistedState from 'vuex-persistedstate'

export default new Vuex.Store({
  //配置
  plugins: [createPersistedState({
    storage: window.sessionStorage
  })],
  state: {
    name: null,
    nickname: null,
    avatar: null,
    phone: null,
    token: null,
    hid: null,
    mid: null,
    info: null,
    auth: false,
    adminAuth: false,
    isManage: 0,
    roleId: 0
  },
  mutations: {
    // 用户登录成功，存储 token 并设置 header 头
    LOGINED(state, data) {
      state.isManage = data.is_manage
      if(data.is_manage == 1){
        // B端
        state.adminAuth = true;
        state.auth = false;
      }else{
        // C端
        state.adminAuth = false;
        state.auth = true;
      }
      state.token = data.token;
      state.roleId = data.role_id;
      sessionStorage.setItem('isManage', state.isManage)
      sessionStorage.setItem('token', state.token)
    },
    // 用户刷新 token 成功，使用新的 token 替换掉本地的token
    REFRESH_TOKEN(state, token) {
      state.token = token;
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + state.token;
    },
    // 登录成功后拉取用户的信息存储到本地
    PROFILE(state, data) {
      state.name = sessionStorage.isManage == 1 ? data.name : data.real_name;
      state.nickname = data.nickname;
      state.phone = data.phone;
      state.avatar = data.avatar;
      state.hid = data.hid;
      state.mid = data.mid;
      state.info = data;
      if(data.role){
        state.roleId = data.role.id
      }
    },
    // 用户登出，清除本地数据
    LOGOUT(state){
      state.name = null;
      state.nickname = null;
      state.phone = null;
      state.avatar = null;
      state.hid = null;
      state.info = null;
      state.auth = false;
      state.adminAuth = false;
      state.isManage = 0;
      state.roleId = 0;
      state.token = null;
    }
  },
  actions: {
    // 登录成功后保存用户信息
    logined({dispatch,commit}, data) {
      return new Promise(function (resolve, reject) {
        commit('LOGINED', data);
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token;
        dispatch('profile').then(() => {
          resolve()
        }).catch(() => {
          reject()
        })
      })
    },
    // 登录成功后使用 token 拉取用户的信息
    profile({commit}) {
      return new Promise(function (resolve, reject) {
        let isManage = sessionStorage.isManage
        let url = process.env.VUE_APP_ADMIN_API_URL + '/rbac/users/info'
        if (isManage == 0) url = process.env.VUE_APP_API_URL + '/user'
        axios.get(url, {}).then(res => {
          let r = res.data;
          if (r.status == 200) {
            if (isManage == 1) {
              // B端
              commit('PROFILE', r.result);
            }else{
              // C端
              commit('PROFILE', r.data);
            }
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    // 用户登出，清除本地数据并重定向至登录页面
    logout({commit}) {
      return new Promise(function (resolve, reject) {
        //commit('logout')
        //window.location.href = '/login'
        /*
        if(state.token){
          axios.get(process.env.API_URL + 'authorizations/logout', {headers:{'Authorization': state.token}}).then(respond => {
            Vue.$router.push({name:'login'})
          })
        }
        */
      })
    },
    // 将刷新的 token 保存至本地
    refreshToken({commit},token) {
      return new Promise(function (resolve, reject) {
        /*
        axios.post(process.env.API_URL + '/authorizations/current', {headers:{'Authorization': token}}).then(respond => {
          let r = respond.data
          if(r.success === 1) {
            commit('refreshToken', r.data.access_token)
          }
        })
        */
      })
    }
  },
  modules: {}
})
