<template>
    <div class="pay">
      <div class="pay-price">
        <h3>￥{{ orderInfo.pay_price }}</h3>
        <span class="time"><span>支付剩余时间：</span><van-count-down :time="time" /></span>
        <p>请在30分钟内完成支付,过期将自动取消订单</p>
      </div>
      <div class="order-info">
        <van-cell-group>
          <van-cell title="订单编号" :value="order_no" />
        </van-cell-group>
        <van-card
            num="1"
            :price="orderInfo.pay_price"
            desc=""
            :title="order.cart_info.title"
            :thumb="order.cart_info.image"
            v-for="order in orderInfo.cart_info"
            :key="order.id"
        />
      </div>
      <div class="user-info">
        <van-cell-group>
          <van-cell title="购买人信息" value="" />
        </van-cell-group>
        <van-cell-group inset>
          <van-field
                  v-model="real_name"
                  name="real_name"
                  label="真实姓名："
                  placeholder="请填写购买人姓名"
                  :rules="[{ required: true, message: '请填写购买人姓名' }]"
          />
          <van-field
                  v-model="user_phone"
                  name="user_phone"
                  label="手机号码："
                  placeholder="请填写购买人手机号"
                  :rules="[{ required: true, message: '请填写购买人手机号' }]"
          />
        </van-cell-group>
      </div>
      <div class="pay-type">
        <van-radio-group v-model="payRadio">
          <van-cell-group>
            <van-cell title="微信支付" clickable @click="payRadio='1'" v-show="wxShow" class="pay-p">
              <template #right-icon>
                <van-radio name="1" checked-color="#ee0a24" />
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
      </div>
      <div class="pay-btn">
        <van-button round type="danger" block @click="callPay">确认支付</van-button>
      </div>
      <Floating />
      <WxNoShare />
    </div>
</template>
<script>
  import { RadioGroup, Radio, Cell, CellGroup, CountDown, Button, Dialog, Card, Form, Toast } from 'vant'
  // import wx from 'weixin-jsapi'

  export default {
    components: {
      [Button.name]: Button,
      [CellGroup.name]: CellGroup,
      [Cell.name]: Cell,
      [RadioGroup.name]: RadioGroup,
      [Radio.name]: Radio,
      [CountDown.name]: CountDown,
      [Dialog.Component.name]: Dialog.Component,
      [Card.name]: Card,
      [Form.name]: Form,
      [Toast.name]: Toast
    },
    name: 'pay',
    data() {
      return {
        payRadio: '1',
        time: 0,
        order_no: '',
        real_name: '',
        user_phone: '',
        wxShow: true,
        orderInfo: {}
      };
    },
    created() {
      this.$api.get('storesOrders/detail', {id:this.$route.query.id}, r=>{
        this.order_no = r.data.order_no
        this.price = r.data.pay_price
        this.time = r.data.residue_time
        this.orderInfo = r.data
      })
    },
    methods: {
      callPay() {
        if (this.real_name == '') {
          Toast('请填写购买人姓名');
          return false;
        }
        if (this.user_phone == '') {
          Toast('请填写购买人手机号');
          return false;
        }
        if (typeof WeixinJSBridge == "undefined"){
          if( document.addEventListener ){
            document.addEventListener('WeixinJSBridgeReady', this.jsApiCall(), false);
          }else if (document.attachEvent){
            document.attachEvent('WeixinJSBridgeReady', this.jsApiCall());
            document.attachEvent('onWeixinJSBridgeReady', this.jsApiCall());
          }
        }else{
          this.jsApiCall();
        }
      },
      jsApiCall() {
        let self = this
        this.$api.post('payments/pay',{id: this.orderInfo.id, real_name: this.real_name, user_phone: this.user_phone}, r=>{
          var data = r.data.result.jsConfig
          window.WeixinJSBridge.invoke(
            'getBrandWCPayRequest',{appId:data.appId, timeStamp: data.timestamp, nonceStr: data.nonceStr, package: data.package, signType: data.signType, paySign: data.paySign,},
            function(res){
              //console.log(res)
              if (res.err_msg == "get_brand_wcpay_request:ok") { // 支付成功
                //window.location.href="/users/index";
                window.location.href = "/payment/succeed?id="+self.orderInfo.id
                //self.$router.push({name:'paymentSucceed', query:{id:self.$route.query.id}})
                return
              }
              if(res.err_msg == "get_brand_wcpay_request:fail") { // 支付失败
                Toast('支付失败');
              }
              if (res.err_msg == "get_brand_wcpay_request:cancel") { // 取消支付
                Toast('取消支付');
              }
            }
          )
        })
      }
    }
  }
</script>

<style scoped>
  .pay{
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    position: fixed;
    overflow: hidden;
  }
  .pay-price{
    text-align: center;
  }
  .pay-price h3{
    font-size: 28px;
    font-weight: normal;
    margin: 15px 0;
  }
  .pay-price .time{
    display: inline-block;
    font-size: 14px;
    background: #F3F3F3;
    border-radius: 5px;
    padding:3px 5px;
  }
  .pay-price .time span{
    float: left;
  }
  .pay-price .time div{
    float: left;
  }
  .pay-price p{
    margin: 0;
    padding-bottom: 15px;
    color:#aeaeb1;
    font-size: 12px;
  }
  .order-info{
    overflow: hidden;
    margin-bottom: 10px;
  }
  .pay-type{
    overflow: hidden;
    padding:0 15px;
  }
  .pay-type .img-icon{
    width: 40px;
    float: left;
  }
  .pay-company{
    font-size: 12px;
    padding:5px 30px;
    color: #a8a8a8;
    line-height: 20px;
  }
  .pay-btn{
    text-align: center;
    margin-top: 20px;
    padding:0 35px;
  }
  .pay-btn button{
    padding:0 60px;
  }
  .user-info{
    overflow: hidden;
  }
  .pay-p{
    padding:10px 0;
  }
</style>
