<template>
    <div>
        <van-nav-bar
            title="添加会员"
            left-text="返回"
            left-arrow
            @click-left="onClickLeft"
        />
        <van-form @submit="onSubmit" class="c-e-form">
            <van-field
                    v-model="real_name"
                    name="real_name"
                    label="姓名"
                    placeholder="请填写姓名"
                    :rules="[{ required: true, message: '请填写姓名' }]"
            />
            <van-field
                    v-model="phone"
                    name="phone"
                    label="手机号"
                    placeholder="请填写手机号"
                    :rules="[{ required: true, message: '请填写手机号' }]"
            />
            <van-field name="gender" label="性别">
                <template #input>
                    <van-radio-group v-model="gender" direction="horizontal">
                        <van-radio name="1">男</van-radio>
                        <van-radio name="2">女</van-radio>
                    </van-radio-group>
                </template>
            </van-field>
            <van-field name="age" label="年龄">
                <template #input>
                    <van-stepper v-model="age" />
                </template>
            </van-field>
            <van-field
                    v-model="birthday"
                    is-link
                    readonly
                    label="生日"
                    name="birthday"
                    placeholder="选择生日"
                    @click="showPicker = true"
            />
            <van-popup v-model:show="showPicker" round position="bottom">
                <van-datetime-picker
                    v-model="currentDate"
                    type="date"
                    title="选择年月日"
                    :min-date="minDate"
                    :max-date="maxDate"
                    @confirm="onConfirmBirthday"
                />
            </van-popup>
            <van-field
                    v-model="identification"
                    name="identification"
                    label="身份证号"
                    placeholder="请输入身份证号"
            />
            <van-field name="consult_pic" label="咨询表">
                <template #input>
                    <van-uploader v-model="fileList" :max-count="4" :after-read="afterRead" />
                </template>
            </van-field>
            <van-field
                    v-model="members_grade_name"
                    name="members_grade_name"
                    is-link
                    readonly
                    label="会员等级"
                    placeholder="点击选择会员等级"
                    @click="showGrade = true"
            />
            <van-popup v-model:show="showGrade" position="bottom">
                <van-picker
                    :columns="membersGrade"
                    :columns-field-names="{text: 'name'}"
                    @confirm="onConfirmGrade"
                    @cancel="showGrade = false"
                />
            </van-popup>
            <van-field
                    v-model="referrer_name"
                    name="referrer_name"
                    is-link
                    readonly
                    label="推荐人"
                    placeholder="点击选择推荐人"
                    @click="showReferrer = true"
            />
            <van-popup v-model:show="showReferrer" position="bottom" closeable>
                <div class="referrer-popup">
                    <div class="r-p-s">
                        <van-search
                                v-model.trim="referrerKeyword"
                                name="search"
                                show-action
                                label="姓名"
                                placeholder="请输入搜索姓名"
                                @search="onSearch"
                        >
                            <template #action>
                                <div @click="onSearch">搜索</div>
                            </template>
                        </van-search>
                    </div>
                    <div class="r-p-m">
                        <van-radio-group v-model="referrer_checked">
                            <van-cell-group inset>
                                <van-cell title="无推荐人" clickable @click="referrerSelect(0, '无推荐人')">
                                    <template #right-icon>
                                        <van-radio name="0" />
                                    </template>
                                </van-cell>
                                <van-cell :title="m.real_name" clickable @click="referrerSelect(m.id, m.real_name)" v-for="m in members" :key="m.id">
                                    <template #right-icon>
                                        <van-radio :name="m.id" />
                                    </template>
                                </van-cell>
                            </van-cell-group>
                        </van-radio-group>
                    </div>
                </div>
            </van-popup>
            <van-field
                    v-model="remarks"
                    rows="2"
                    autosize
                    label="备注"
                    type="textarea"
                    name="remarks"
                    maxlength="50"
                    placeholder="请输入备注"
                    show-word-limit
            />
            <van-field name="status" label="状态">
                <template #input>
                    <van-radio-group v-model="status" direction="horizontal">
                        <van-radio name="1">正常</van-radio>
                        <van-radio name="0">冻结</van-radio>
                    </van-radio-group>
                </template>
            </van-field>
            <div style="margin: 16px;">
                <van-button round block type="primary" native-type="submit">
                    提交
                </van-button>
            </div>
        </van-form>
    </div>
</template>

<script>
    import { Form, Field, Cell, CellGroup, RadioGroup, Radio, Stepper, DatetimePicker, Picker, Popup, Uploader, NavBar, Search } from 'vant';
    import axios from 'axios'

    export default {
        components: {
            [Form.name]: Form,
            [Field.name]: Field,
            [Cell.name]: Cell,
            [CellGroup.name]: CellGroup,
            [RadioGroup.name]: RadioGroup,
            [Radio.name]: Radio,
            [Stepper.name]: Stepper,
            [DatetimePicker.name]: DatetimePicker,
            [Picker.name]: Picker,
            [Popup.name]: Popup,
            [Uploader.name]: Uploader,
            [NavBar.name]: NavBar,
            [Search.name]: Search
        },
        name: "create_edit",
        data () {
            return {
                real_name: '',
                phone: '',
                gender: '1',
                age: 30,
                members_grade_name: '',
                members_grade_id: 0,
                birthday: '',
                identification: '',
                remarks: '',
                currentDate: new Date(1960, 0, 1),
                minDate: new Date(1960, 0, 1),
                maxDate: new Date(2025, 10, 1),
                showPicker: false,
                consult_pic: '',
                consult_files: '',
                pic: [],
                fileList: [],
                showGrade: false,
                status: '1',
                showReferrer: false,
                referrerKeyword: '',
                referrer_id: 0,
                referrer_name: '',
                referrer_checked: false,
                membersGrade: [],
                members: [],
                postData: []
            }
        },
        created () {
            this.getMembersStore();
            this.getMembersGrade();
        },
        methods: {
            getMembersStore() {
                this.$api.get('membersStore', {pageSize:2000, realName:this.referrerKeyword}, r=>{
                    this.members = r.result.data;
                })
            },
            getMembersGrade() {
                this.$api.get('membersGrade', {pageSize: 100}, r=>{
                    this.membersGrade = r.result.data;
                })
            },
            onConfirmBirthday(value) {
                this.showPicker = false;
                let formatDate = (date) => `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
                // console.log('日期：',formatDate(value));
                this.birthday = formatDate(value)
            },
            onConfirmGrade(val) {
                this.members_grade_name = val.name
                this.members_grade_id = val.id
                this.showGrade = false
            },
            onClickLeft() {
                history.back();
            },
            onSubmit (values) {
                let self = this
                values.members_grade_id = this.members_grade_id
                values.referrer_id = this.referrer_checked
                // console.log('submit', values);
                delete values.members_grade_name
                delete values.referrer_name
                this.$api.post('membersStore', values, r=>{
                    self.$toast({'message':r.message, onOpened:function(){
                        if(r.status == 200){
                            self.$router.push({name: 'AdminMember'})
                        }
                    }})
                })
            },
            /*
            afterRead(file) {
                let self = this
                let img = {url:'https://img.yangfayun.com/ganfa/def_1.jpg', status: 'uploading', uid: Date.parse(new Date())}
                file.status = 'uploading';
                file.message = '上传中...';
                this.postData.push(file)  // postData是一个数组
                let url = process.env.VUE_APP_ADMIN_API_URL + '/images'
                let fd = new FormData()
                fd.append('file', file.file)
                axios.post(url, fd, {headers: {'Content-Type': 'multipart/form-data'}}).then(res => {
                    file.status = 'done'
                    img.url = res.data.result.path
                    img.status = 'done'
                    self.pic.push({url:res.data.result.path, status: 'done', uid: Date.parse(new Date())})
                    // self.consult_pic = res.data.result.path
                }).catch(err => {
                    alert(err)
                })
                console.log("pic", self.pic)
            },
            */
            afterRead(file) {
                let self = this
                file.status = 'uploading';
                file.message = '上传中...';

                let url = process.env.VUE_APP_ADMIN_API_URL + '/images'
                let fd = new FormData()
                fd.append('file', file.file)
                axios.post(url, fd, {headers: {'Content-Type': 'multipart/form-data'}}).then(res => {
                    file.status = 'done';
                    file.message = '上传完成';
                    delete self.fileList[this.fileList.length - 1].file
                    delete self.fileList[this.fileList.length - 1].content
                    self.fileList[this.fileList.length - 1].url = res.data.result.path;
                    // self.record.photo = JSON.parse(JSON.stringify(self.fileList))
                }).catch(err => {
                    alert(err)
                })
            },
            onSearch() {
                this.getMembersStore();
            },
            referrerSelect(val, name) {
                this.referrer_checked = val
                this.referrer_name = name
                this.showReferrer = false
            }
        }
    }
</script>

<style scoped>
    .c-e-form{
        background: #ffffff;
        padding-bottom: 20px;
    }
    .referrer-popup{
        overflow: hidden;
        margin: 10px;
        min-height: 360px;
    }
    .referrer-popup .r-p-s{
        margin:0 45px 0 5px;
    }
</style>
