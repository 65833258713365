<template>
    <div class="bg">
        <h3 class="title">服务预约</h3>
        <van-form @submit="onSubmit">
            <van-cell
                    title="预约日期"
                    :value="date"
                    @click="showPopupTime"
                    is-link
            />
            <van-field
                    v-model="projectName"
                    name="projectName"
                    is-link
                    readonly
                    label="服务项目"
                    placeholder="选择服务项目"
                    @click="showPicker = true"
            />
            <van-popup v-model:show="showPicker" round position="bottom">
                <van-picker
                        :columns="projects"
                        @cancel="showPicker = false"
                        @confirm="onConfirm"
                />
            </van-popup>
            <van-field
                    v-model="staffName"
                    name="staffName"
                    is-link
                    readonly
                    label="服务人员"
                    placeholder="选择服务人员"
                    @click="showStaff"
            />
            <van-popup v-model:show="showPickerStaff" round position="bottom">
                <van-picker
                        :columns="staffs"
                        @cancel="showPickerStaff = false"
                        @confirm="onConfirmStaff"
                />
            </van-popup>
            <!-- <van-calendar v-model:show="show" :show-confirm="false" @confirm="onConfirmDate" />-->
            <van-field
                    v-model="userName"
                    name="userName"
                    label="真实姓名"
                    placeholder="请填写真实姓名"
            />
            <van-field
                    v-model="phone"
                    name="phone"
                    label="手机号"
                    placeholder="请填写手机号"
            />
            <van-cell-group inset>
                <van-field
                    v-model="message"
                    name="message"
                    rows="3"
                    autosize
                    label="备注信息"
                    type="textarea"
                    maxlength="100"
                    placeholder="请输入..."
                    show-word-limit
                    style="padding:10px 0"
                />
            </van-cell-group>
            <div class="sub-msg">
                提示：预约成功后1小时内本店客服将会与您联系确认预约
            </div>
            <div class="sub-btn">
                <van-button round block type="danger" native-type="submit">提交预约</van-button>
            </div>
        </van-form>
        <van-popup
            v-model:show="showTime"
            position="bottom"
        >
            <Times @change="getTime" :appointTime="appointTime" :isMultiple="false" :isSection="false" :disableTimeSlot="disableTimeSlot"></Times>
        </van-popup>
        <Footer />
        <WxNoShare />
    </div>
</template>

<script>
    import Times from '@/components/pretty-times/pretty-times.vue'
    import { Picker,Popup,Calendar,Cell, CellGroup,Form } from 'vant';
    export default {
        components: {
            [Form.name]: Form,
            [Calendar.name]: Calendar,
            [Picker.name]: Picker,
            [Popup.name]: Popup,
            [CellGroup.name]: CellGroup,
            [Cell.name]: Cell,
            Times
        },
        name: "subscribe",
        data() {
            return {
                show: false,
                showTime: false,
                showPicker: false,
                showPickerStaff: false,
                date: '',
                userName: '',
                phone: '',
                projectName: '',
                staffName: '',
                message: '',
                projects: [],
                staffs: [],
                appointTime: [],
                disableTimeSlot: {
                    begin_time: "2021-11-11 09:00",
                    end_time: "2021-11-11 10:00"
                }
            }
        },
        created () {
            this.getMember();
            this.getProject();
            this.getScheduling();
            this.staffName = this.$route.query.staffName
        },
        methods: {
            getMember() {
                this.$api.get('storesMembers/info', null, r=>{
                    if(r.data.store_member != null) {
                        this.userName = r.data.store_member.real_name
                        this.phone = r.data.store_member.phone
                    }
                })
            },
            getProject() {
                this.$api.get('storeProject/cate', {mid: sessionStorage.getItem('mid')}, r=>{
                    r.data.map(item =>{
                        this.projects.push(item.name)
                    });
                })
            },
            getStaff() {
                this.$api.get('storeProject/staff', {mid: sessionStorage.getItem('mid'), date: this.date}, r=>{
                    r.data.filter((ele) => {
                        let name = ele.name
                        if(ele.roles[0].id != 9) {
                            if(ele.disabled){
                               name = ele.name + '（休息）'
                            }
                            this.staffs.push({ id: ele.id, text: name, disabled: ele.disabled })
                        }
                    })
                })
            },
            getScheduling() {
                this.$api.get('storesMembers/scheduling', {mid: sessionStorage.getItem('mid')}, r=>{
                    this.appointTime = r.data
                })
            },
            onSubmit(values) {
                if(this.date == ''){
                    this.$toast('请选择预约日期')
                    return
                }
                if(values.projectName == ''){
                    this.$toast('请选择服务项目')
                    return
                }
                if(values.staffName == ''){
                    this.$toast('请选择服务人员')
                    return
                }
                if(values.userName == ''){
                    this.$toast('请填写真实姓名')
                    return
                }
                if(values.phone == ''){
                    this.$toast('请填写手机号')
                    return
                }
                values.appointmentDate = this.date
                this.$api.post('storesMembers/subscribeStore', {project_name: values.projectName, staff_name: values.staffName, remarks: values.message, appointment_date: values.appointmentDate, name: values.userName, phone: values.phone, mid: sessionStorage.getItem('mid')}, r=>{
                    this.$toast({'message':r.message, onOpened:function(){
                        if(r.status == 200){
                            window.location.reload()
                        }
                    }})
                })
            },
            onConfirm (value) {
                this.projectName = value;
                this.showPicker = false;
            },
            showStaff () {
                if(this.date){
                    this.showPickerStaff = true
                }else{
                    this.$toast('请选择预约日期')
                    return
                }
            },
            onConfirmStaff (value) {
                this.staffName = value.text;
                this.showPickerStaff = false;
            },
            onConfirmDate (value) {
                let formatDate = (date) => `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
                this.show = false;
                this.date = formatDate(value);
            },
            showPopupTime() {
                this.showTime = true
            },
            getTime(e) {
                console.log('预约日期：', e)
                this.date = e
                this.showTime = false
                this.getStaff()
            }
        }
    }
</script>

<style scoped>
    .title{
        font-size: 16px;
        text-align: center;
    }
    .sub-btn{
        padding:30px 15px;
        margin-top:10px;
    }
    .sub-msg{
        padding:15px;
        color:#b3b0b0;
        background: #F2F3F5;
    }
</style>
