<template>
    <div v-show="show">
        <div class="banner">
            <img :src="details.image" />
        </div>
        <div class="d-info">
            <h3>{{details.title}}</h3>
            <div class="price">
                <span class="p-l">￥{{details.price}} <em v-if="details.line_price != null">￥{{details.line_price}}</em></span>
                <span class="time" v-if="details.pay_status == 1"><van-count-down :time="details.time" format="还剩DD天HH:mm:ss" /></span>
            </div>
            <div class="see-show">
                <span class="left"><em>{{details.sales + details.sales_initial}}</em>人购买</span>
                <div class="right s-s-r">
                    <span><em>{{details.view_count}}</em>人查看</span>
                    <!--<span class="sp-2"><em>500</em>人分享</span>-->
                </div>
            </div>
        </div>
        <div class="d-content">
            <span class="title">活动详情</span>
            <div class="content" v-html="details.content"></div>
        </div>
        <van-action-bar>
            <van-action-bar-button type="info" text="海报分享" @click="onClickPoster" />
            <van-action-bar-button type="danger" text="立即购买" @click="onClickButton" v-if="details.pay_status == 1" />
            <van-action-bar-button type="danger" text="活动未开始" v-else-if="details.pay_status == 2" />
            <van-action-bar-button type="danger" text="活动已结束" v-else-if="details.close_status == 1" />
        </van-action-bar>
        <div class="user-suspension" @click="toUrl('user')"><van-icon name="user-o" class="user" /></div>
        <!--海报-->
        <van-popup v-model:show="posterShow" :style="{ width: '70%' }">
            <div class="poster">
                <div class="p-img"><img :src="posterUrl" /></div>
                <div class="p-title">长按图片保存相册</div>
            </div>
        </van-popup>
    </div>
</template>

<script>
    import { ActionBar, ActionBarIcon, ActionBarButton, CountDown, Popup, Image as VanImage } from 'vant';
    import wx from 'weixin-jsapi';
    export default {
        components: {
            [ActionBar.name]: ActionBar,
            [ActionBarButton.name]: ActionBarButton,
            [ActionBarIcon.name]: ActionBarIcon,
            [CountDown.name]: CountDown,
            [Popup.name]: Popup,
            [VanImage.name]: VanImage
        },
        name: "activityDetails",
        data() {
            return {
                show: false,
                posterShow: false,
                id: 0,
                hid: 0,
                details: {},
                shareLink: '',
                posterUrl: 'https://img.yangfayun.com/yfy/poster_def.jpg'
            }
        },
        created () {
            this.id = this.$route.query.id;
            this.hid = this.$route.query.hid;
            this.getDetails();
            this.shareLink = window.location.href;
            this.getJssdkConfig("onMenuShareTimeline,checkJsApi,onMenuShareAppMessage",false,false,this.shareLink);
        },
        methods: {
            toUrl (name) {
                this.$router.push({name: name})
            },
            getDetails() {
                const regex = new RegExp('<img', 'gi');
                this.$api.get('activity/details', {id: this.id}, r=>{
                    this.details = r.data;
                    if (this.details.content != null) {
                        this.details.content = this.details.content.replace(regex, `<img style="max-width: 100%; height: auto"`);
                    }
                    if(this.details.time > 0){
                        this.details.time = this.details.time * 1000;
                    }
                    sessionStorage.setItem('mid', this.details.mid);
                    this.show = true;
                })
            },
            onClickPoster() {
                let _self = this
                this.$api.post('poster/store', {activity_id: this.details.id}, r=>{
                    _self.$toast.loading({'message':'绘制中', onOpened:function(){
                        if(r.status == 200){
                            _self.posterUrl = r.data.url
                            _self.posterShow = true
                        }
                    }})
                })
            },
            onClickButton() {
                this.$api.post('storesOrders/create', {goods_id: this.details.id, hid: this.hid}, r=>{
                    this.$router.push({name: 'paymentIndex', query: {id: r.data.result.orderId, paytype: 'weixin'}})
                })
            },
            getJssdkConfig(apis,debug,json,link){
                let self = this
                this.$api.get('jssdkConfig', {apis:apis, debug:debug, json:json, url: link}, r=>{
                    let config = r.data;
                    wx.config(config)
                    wx.ready(function() {
                        var shareData = {
                            title: self.details.share_title,
                            desc: self.details.share_brief,
                            link: self.shareLink,
                            imgUrl: self.details.image,
                            success: function () {
                                self.$api.post('activity/share', {id: self.details.id})
                            }
                        }
                        wx.showOptionMenu()
                        wx.onMenuShareAppMessage(shareData)
                        wx.onMenuShareTimeline(shareData)
                    })
                })
            }
        }
    }
</script>

<style scoped>
    .banner{
        overflow: hidden;
    }
    .banner img{
        width: 100%;
    }
    .d-info{
        overflow: hidden;
        padding:10px;
        background: #ffffff;
    }
    .d-info h3{
        margin: 0;
        font-size: 15px;
    }
    .d-info .price{
        overflow: hidden;
        line-height: 30px;
        margin-top: 5px;
    }
    .d-info .price .p-l{
        float: left;
        font-size: 18px;
        color:#EE0A24;
        font-weight: bold;
    }
    .d-info .price .p-l em{
        font-style: normal;
        font-size: 13px;
        font-weight: normal;
        color:#A2A1A3;
        text-decoration: line-through;
        margin-left: 2px;
    }
    .d-info .price .time{
        float: right;
        font-size: 14px;
    }
    .d-info .see-show{
        overflow: hidden;
    }
    .d-info .see-show em{
        color:#EE0A24;
        font-style: normal;
    }
    .d-info .see-show .s-s-r .sp-2{
        margin-left: 10px;
    }
    .d-content{
        overflow: hidden;
        background: #ffffff;
        padding-bottom:10px;
        margin-top:10px;
    }
    .d-content .title{
        display: block;
        text-align: left;
        font-size: 15px;
        font-weight: bold;
        padding:10px;
        color:#393939;
    }
    .d-content .content{

    }
    .d-content .content img{
        width: 100%;
    }
    .poster{
        overflow: hidden;
    }
    .poster .p-img{
        overflow: hidden;
    }
    .poster .p-img img{
        width: 100%;
    }
    .poster .p-title{
        text-align: center;
        line-height: 30px;
        font-weight: bold;
    }
</style>
