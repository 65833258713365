<template>
  <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
    <van-swipe-item v-for="ad in baseData['ads']" :key="ad.id"><img :src="ad.thumbnail" class="sw-img" /></van-swipe-item>
  </van-swipe>
  <div class="home" v-show="show">
    <div class="menus">
      <ul>
        <li v-for="menu in baseData['nav_menus']" :key="menu.active">
          <router-link :to="`/project/index?mid=`+mid+`&active=`+menu.active">
            <img :src="menu.image" />
            <span>{{ menu.name }}</span>
          </router-link>
        </li>
      </ul>
      <img src="../../assets/images/index/bg_02.jpg" class="bg" />
    </div>
    <div class="index-ad">
      <div class="img"><img src="https://img.yangfayun.com/yfy/bg_01.png" /><span>白发一族养发馆</span></div>
    </div>
    <div class="index-y">
      <div class="i-y-li" @click="see(1)">
        <h3>{{baseData['config'].name}}</h3>
        <p>地址：{{baseData['config'].address}}</p>
        <p class="i-b">电话：{{baseData['config'].hotline}}</p>
      </div>
      <div class="i-y-li i-y-li-r" @click="see(2)">
        <h3>在线预约</h3>
        <p>您的专属养发师</p>
        <p class="i-b i-b-2">点我预约</p>
      </div>
    </div>
    <div class="index-p">
      <div class="i-p-title">
        <img src="../../assets/images/index/t_1.png" />
        <span>只给挑选的你选好货</span>
      </div>
      <ul>
        <li v-for="s in baseData['super_list']" :key="s.id">
          <router-link to="">
            <div class="i-li">
              <router-link :to="{name:'projectDetails', query:{id:s.id, mid:mid}}">
                <img :src="s.image" />
                <h3>{{ s.name }}</h3>
                <!--
                <span class="sp-1">套餐价</span>
                <span class="sp-2">￥288.00</span>
                -->
                <span class="sp-3">已售{{ s.sales_initial + s.sales }}件</span>
              </router-link>
            </div>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="index-store" v-if="baseData['config'].content">
      <h3 class="i-s-title">门店展示</h3>
      <div class="i-content" v-html="baseData['config'].content"></div>
    </div>
    <div class="index-f">
      <div class="i-f-m">
        <img src="../../assets/images/index/b_f.jpg"/>
        <div class="info">
          <h3>{{baseData['config'].name}}</h3>
          <p>地址：{{baseData['config'].address}}</p>
          <p>电话：{{baseData['config'].hotline}}</p>
        </div>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script>
  import { Swipe, SwipeItem } from 'vant';
  import wx from 'weixin-jsapi';

  export default {
    components: {
      [Swipe.name]: Swipe,
      [SwipeItem.name]: SwipeItem
    },
    name: 'Home',
    data() {
      return {
        rwmShow: false,
        show: false,
        shareLink: '',
        merchant: {},
        baseData: {ads:[], hot_list:[], super_list:[], nav_menus:[], config:{}},
        mid: ''
      }
    },
    created () {
      if(this.$route.query.type == 1){
        this.$store.commit('logout')
      }
      this.mid = sessionStorage.getItem('mid');
      this.getIndex();
      this.getShare();
      this.shareLink = window.location.href;
      this.getJssdkConfig("onMenuShareTimeline,checkJsApi,onMenuShareAppMessage",false,false,this.shareLink);
      this.getVisit();
    },
    methods: {
      getVisit() {
        this.$api.get('store/visit', { url: window.location.href})
      },
      getIndex() {
        const regex = new RegExp('<img', 'gi');
        this.$api.get('store/index', {mid: sessionStorage.getItem('mid')}, r=>{
          this.baseData = r.data;
          if (this.baseData['config'].content != null) {
            this.baseData['config'].content = this.baseData['config'].content.replace(regex, `<img style="max-width: 100%; height: auto"`);
          }
          this.show = true;
        })
      },
      getShare() {
        this.$api.get('store/share', {mid: sessionStorage.getItem('mid')}, r=>{
          this.merchant = r.data;
        })
      },
      getJssdkConfig(apis,debug,json,link){
        let self = this
        this.$api.get('jssdkConfig', {apis:apis, debug:debug, json:json, url: link}, r=>{
          let config = r.data;
          wx.config(config)
          wx.ready(function() {
            var shareData = {
              title: self.merchant.name,
              desc: self.merchant.brief,
              link: self.shareLink,
              imgUrl: self.merchant.image,
              success: function () {
                //$.get('/shareHits/'+$("#act_id").val(),{},function(){});
              }
            }
            wx.showOptionMenu()
            wx.onMenuShareAppMessage(shareData)
            wx.onMenuShareTimeline(shareData)
          })
        })
      },
      see(t) {
        if(t === 1){
          console.log(11)
        }else{
          this.$router.push({ name: 'subscribeStaff' })
        }
      }
    }
  }
</script>
<style scoped>
  body{
    background: #ffffff;
  }
  .home{
    overflow: hidden;
  }
  .my-swipe .van-swipe-item {
    text-align: center;
  }
  .sw-img{
    width: 100%;
  }
  .menus{
    overflow: hidden;
    padding-top:15px;
    position: relative;
  }
  .menus .bg{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: -1;
  }
  .menus ul li{
    width: 20%;
    float: left;
    text-align: center;
    margin-bottom: 15px;
  }
  .menus ul li img{
    width: 50px;
  }
  .menus ul li span{
    display: block;
    color:#dfa451;
    font-size: 13px;
    height: 20px;
    overflow: hidden;
  }
  .index-ad{
    overflow: hidden;
    padding:0 15px 15px;
    height: 70px;
    background: #ffffff;
  }
  .index-ad .img{
    position: relative;
    height: 70px;
  }
  .index-ad .img img{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70px;
  }
  .index-ad .img span{
    font-size: 15px;
    text-align: center;
    color:#dfa451;
    position: absolute;
    left: 90px;
    top: 18px;
    font-weight: bold;
  }
  .index-y{
    overflow: hidden;
    padding:0 15px 15px;
    background: #ffffff;
  }
  .index-y .i-y-li{
    float: left;
    background: url(../../assets/images/in_bg_1.png) no-repeat;
    background-size: 100%;
    width: 41%;
    padding:8px 12px;
    height: 80px;
    position: relative;
    color:#fff;
  }
  .index-y .i-y-li h3{
    margin-top: 0;
    margin-bottom: 2px;
    overflow: hidden;
  }
  .index-y .i-y-li p{
    margin: 0;
  }
  .index-y .i-y-li .i-b{
    position: absolute;
    left: 12px;
    bottom: 8px;
  }
  .index-y .i-y-li .i-b-2{
    right: 12px;
  }
  .index-y .i-y-li-r{
    float: right;
    background: url(../../assets/images/in_bg_2.png) no-repeat;
    background-size: 100%;
    text-align: right;
  }
  .index-p{
    overflow: hidden;
    border-top:3px solid #c3a682;
    background: url(../../assets/images/index/bg_01.jpg) no-repeat top center #f9f9f9;
    padding-bottom: 15px;
  }
  .index-p .i-p-title{
    overflow: hidden;
    text-align: center;
    margin: 15px 0;
  }
  .index-p .i-p-title img{
    width: 120px;
  }
  .index-p .i-p-title span{
    font-size: 12px;
    color:#4f3d29;
    display: block;
    text-align: center;
  }
  .index-p ul{}
  .index-p ul li{
    float: left;
    width: 50%;
  }
  .index-p ul li .i-li{
    overflow: hidden;
    border:1px solid #c3a682;
    border-radius: 5px;
    margin: 15px;
    padding:10px;
  }
  .index-p ul li .i-li img{
    width: 100%;
  }
  .index-p ul li .i-li h3{
    color:#3a3a3a;
    font-size: 14px;
    font-weight: normal;
    margin: 5px 0;
    height: 20px;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
  }
  .index-p ul li .i-li span{
    display: block;
  }
  .index-p ul li .i-li .sp-1{
    font-size: 12px;
    color:#d61717;
  }
  .index-p ul li .i-li .sp-2{
    color:#d61717;
    font-size: 14px;
    font-weight: bold;
    margin: 2px 0;
  }
  .index-p ul li .i-li .sp-3{
    font-size: 13px;
    color:#b6b2b2;
  }
  .index-f{
    background: #ffffff;
    padding:15px;
    overflow: hidden;
  }
  .index-f .i-f-m{
    position: relative;
    height: 100px;
  }
  .index-f .i-f-m img{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  .index-f .i-f-m .info{
    position: absolute;
    top: 15px;
    left: 100px;
    overflow: hidden;
  }
  .index-f .i-f-m .info h3{
    margin: 5px 0;
    font-size: 14px;
    color:#4f3d29;
  }
  .index-f .i-f-m .info p{
    margin: 0;
    font-size: 12px;
    color:#4f3d29;
    line-height: 22px;
    font-weight: bold;
  }
  .index-store{
    overflow: hidden;
    background: #ffffff;
    padding:15px;
  }
  .index-store .i-s-title{
    margin: 0;
    color: #dfa451;
    text-align: center;
    font-size: 16px;
  }
  .i-content{
    overflow: hidden;
  }
  .i-content img{
    width: 100%;
  }
</style>
