<template>
    <div class="succeed">
      <div class="succeed-a">
        <span class="icon"><van-icon name="passed" /></span>
        <h3>订单支付成功</h3>
        <span class="price">￥{{ order.pay_price }}</span>
      </div>
      <div class="succeed-btn">
        <!--<router-link :to="{ name: 'ordersShow', query: { id: order.id }}"><span>查看订单</span></router-link>-->
        <router-link to="/"><van-button round type="default" class="sp-1">返回首页</van-button></router-link>
        <router-link :to="{ name: 'OrderDetails', query: { id: order.id }}"><van-button round type="danger" class="sp-1">查看订单</van-button></router-link>
      </div>
      <div class="van-hairline--top"></div>
      <div class="succeed-rwm">
        <p class="p-0">养发云平台</p>
        <img src="../../assets/images/rwm.jpg" width="170" />
        <p class="p-1">长按关注公众号</p>
        <p class="p-2">关注公众号后方便查看信息</p>
      </div>

      <wxNoShare />
    </div>
</template>
<script>
  import { Cell, CellGroup, Button, Icon } from 'vant'

  export default {
    components: {
      [Button.name]: Button,
      [CellGroup.name]: CellGroup,
      [Cell.name]: Cell,
      [Icon.name]: Icon,
    },
    name: 'paymentSucceed',
    data() {
      return {
        order: []
      };
    },
    created() {
      this.getOrders()
    },
    methods: {
      getOrders() {
        this.$api.get('storesOrders/detail', {id: this.$route.query.id}, r=>{
          this.order = r.data
        })
      }
    }
  }
</script>

<style scoped>
  .succeed{
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    position: fixed;
    overflow: hidden;
  }
  .succeed-a{
    margin-top: 15%;
    text-align: center;
  }
  .succeed-a .icon{
    font-size: 60px;
    color:#07c160;
  }
  .succeed-a h3{
    margin-top: 0px;
    font-weight: normal;
  }
  .succeed-a .price{
    font-size: 24px;
    font-weight: bold;
  }
  .succeed-a p{
    color:#969799;
    font-size: 14px;
  }
  .succeed-btn{
    text-align: center;
    margin: 10% 0;
  }
  .succeed-btn .sp-1{
    margin:0 10px;
    padding:0 30px;
  }
  .succeed-rwm{
    text-align: center;
    padding-top:10px;
  }
  .succeed-rwm .p-0{
    margin: 0;
    font-size: 15px;
    font-weight: bold;
  }
  .succeed-rwm .p-1{
    margin: 3px 0;
    font-size: 14px;
  }
  .succeed-rwm .p-2{
    font-size: 14px;
    color:#969799;
  }
</style>
