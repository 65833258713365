<template>
  <div>
    <div class="user-top">
      <img src="../../../assets/images/admin/bg_02.jpg" class="img" />
      <div class="user-t-m">
        <div class="user-t-m-1"><img v-if="userInfo.avatar" :src="userInfo.avatar" /><img v-else src="../../../assets/images/user_def.png" /></div>
        <div class="user-t-m-2">
            <span v-if="userInfo">
              <p class="u-name" style="margin-top: 4px;">{{ userInfo.nickname }}</p>
              <div class="u-n-info">
                <p>姓   名：{{ userInfo.name }}<span class="u-n-p"><img src="../../../assets/images/admin/ico_01.png" class="u-n-p-img" />{{ userInfo.role.name }}</span></p>
                <p>手机号：{{ userInfo.phone }}</p>
              </div>
            </span>
          <div v-else class="user-login">会员登录</div>
        </div>
      </div>
    </div>
    <div class="user-a">
      <div class="u-a-m">
        <span class="u-a-title">我的总收益</span>
        <span class="u-a-price">{{statistics.sum_account}}(元)</span>
      </div>
      <div class="u-a-p">
        <div class="u-a-p-l" @click="profit(0)">
          <span>{{statistics.project_account}}</span>
          <span class="sp-2">项目收益(元) ></span>
        </div>
        <div class="u-a-p-r" @click="profit(1)">
          <span>{{statistics.marketing_account}}</span>
          <span class="sp-2">营销收益(元) ></span>
        </div>
      </div>
    </div>
    <div class="user-b">
      <router-link to="/admin/user/record"><img src="../../../assets/images/admin/01.png" class="u-b-i" /></router-link>
      <router-link :to="'/activity/index?mid='+userInfo.mid"><img src="../../../assets/images/admin/02.png" class="u-b-i" /></router-link>
    </div>
    <div class="">
      <router-link to=""><van-cell title="帮助" icon="service-o" is-link /></router-link>
<!--      <router-link to=""><van-cell title="设置" icon="setting-o" is-link /></router-link>-->
    </div>
    <!--提成记录-->
    <van-popup v-model:show="showRecord" position="bottom" :style="{ height: 'auto' }" closeable>
      <div class="profit-item" v-for="p in profitList" :key="p.id">
        <div class="p-i-l">
          <p v-if="p.orders">{{p.orders.member_store_project.stores_project_info.name}}<span><template v-if="p.orders.order_type == 2">营销订单</template><template v-else-if="p.orders.order_type == 3">项目订单</template></span></p>
          <p v-else>手工</p>
          <p><!--价格：{{p.pay_price}}--><span class="p-i-t">{{p.created_at}}</span></p>
        </div>
        <div class="p-i-r">
          <p><template v-if="p.status == 1">已清算</template><span v-else class="red">未清算</span></p>
          <p>提成：{{p.price}}</p>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
  import { Icon, Badge, Popup, Dialog, Cell, CellGroup, Collapse, CollapseItem } from 'vant'

  export default {
    components: {
      [Icon.name]: Icon,
      [Badge.name]: Badge,
      [Popup.name]: Popup,
      [Cell.name]: Cell,
      [CellGroup.name]: CellGroup,
      [Collapse.name]: Collapse,
      [CollapseItem.name]: CollapseItem,
      [Dialog.Component.name]: Dialog.Component,
    },
    name: 'index',
    data() {
      return {
        show: false,
        showRecord: false,
        activeNames: [],
        userInfo: {mid:'', nickname:'', name:'', avatar:'', role:{name:''}},
        statistics: {},
        profitList: []
      }
    },
    created () {
      this.getUser();
      this.getStatistics();
    },
    methods: {
      getUser() {
        this.$api.get('rbac/users/info', null, r=>{
          this.userInfo = r.result;
        })
      },
      getStatistics() {
        this.$api.get('stores/userStatistics', null, r=>{
          this.statistics = r.result;
        })
      },
      profit(type) {
        this.$api.get('stores/profitRecord', {id:this.userInfo.id, type:0, comm_type: type}, r=>{
          this.profitList = r.result;
          if(this.profitList.length > 0){
            this.showRecord = true
          }
        })
      },
      toMyUrl(name) {
        this.$router.push({name: name})
      }
    }
  }
</script>

<style scoped>
  .red{
    color:red;
  }
  .u-n-p-img{
    width: 20px;
    margin-right: 2px;
  }
  .user-a{
    margin-top: 10px;
    overflow: hidden;
    padding:10px 15px;
    background: #ffffff;
  }
  .user-a .u-a-m{
    background: #0082fe;
    color:#ffffff;
    border-radius: 10px;
    font-size: 14px;
    line-height: 65px;
  }
  .user-a .u-a-m .u-a-title{
    margin-left: 50px;
  }
  .user-a .u-a-m .u-a-price{
    float: right;
    margin-right: 50px;
    font-size: 16px;
  }
  .user-a .u-a-p{
    overflow: hidden;
    color:#333333;
    font-size: 14px;
    margin: 15px 0 5px;
  }
  .user-a .u-a-p span{
    display: block;
    font-weight: bold;
  }
  .user-a .u-a-p .sp-2{
    color:#a5a0a0;
    font-weight: normal;
    font-size: 13px;
  }
  .user-a .u-a-p .u-a-p-l{
    float: left;
    width: 50%;
    text-align: center;
  }
  .user-a .u-a-p .u-a-p-r{
    float: right;
    width: 50%;
    text-align: center;
  }
  .user-b{
    margin-top: 10px;
    overflow: hidden;
    padding:15px;
    background: #ffffff;
  }
  .user-b .u-b-i{
    width: 100%;
    margin-bottom: 10px;
  }
  .profit-item{
    overflow: hidden;
    margin:10px;
    border-bottom: 1px solid #f4f4f4;
    padding-bottom: 10px;
  }
  .profit-item:last-child{
    border-bottom: none;
  }
  .profit-item p{
    margin: 0;
  }
  .profit-item .p-i-l{
    width: 70%;
    float: left;
  }
  .profit-item .p-i-l .p-i-t{
    margin-left: 0px;
    font-size: 12px;
    color:#a3a2a2;
  }
  .profit-item .p-i-l p span{
    margin-left: 10px;
  }
  .profit-item .p-i-r{
    width: 30%;
    float: right;
  }
</style>
