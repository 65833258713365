<template>
    <div>
        <van-nav-bar
                title="我的预约"
                left-text="返回"
                left-arrow
                @click-left="onClickLeft"
        />
        <div class="s-li" v-for="subscribe in subscribes" :key="subscribe.id">
            <van-row>
                <van-col span="12">姓名: {{ subscribe.name }}</van-col>
                <van-col span="12">状态: <template v-if="subscribe.status == 1">预约成功</template><template v-else><span class="s-red">待确认</span></template></van-col>
            </van-row>
            <van-row>
                <van-col span="12">服务项目: {{ subscribe.project_name }}</van-col>
                <van-col span="12">服务人员: {{ subscribe.staff_name }}</van-col>
            </van-row>
            <van-row>
                <van-col span="24">预约日期: {{ subscribe.appointment_date }}</van-col>
            </van-row>
            <van-row v-if="subscribe.remarks != null">
                <van-col span="24">备注信息: {{ subscribe.remarks }}</van-col>
            </van-row>
        </div>
        <Floating skipUrl="/user/index" />
        <wxNoShare />
    </div>
</template>

<script>
    import { Collapse, CollapseItem, NavBar } from 'vant'

    export default {
        components: {
            [Collapse.name]: Collapse,
            [CollapseItem.name]: CollapseItem,
            [NavBar.name]: NavBar,
        },
        name: 'usersSubscribe',
        data() {
            return {
                subscribes: []
            }
        },
        created () {
            this.getSubscribe()
        },
        methods: {
            getSubscribe() {
                this.$api.get('storesMembers/subscribe', {mid: sessionStorage.getItem('mid')}, r=>{
                    this.subscribes = r.data;
                })
            },
            onClickLeft() {
                history.back()
            }
        }
    }
</script>

<style scoped>
    .s-li{
        overflow: hidden;
        background: #ffffff;
        padding:10px;
        margin-bottom: 15px;
        font-size: 14px;
    }
    .s-red{
        color:#EE0A24;
    }
</style>
