<template>
  <div>
    <div class="h-search">
      <van-search
          v-model="keyWord"
          show-action
          placeholder="请输入姓名"
          @search="getMembersStore"
      >
        <template #action>
          <div @click="getMembersStore">搜索</div>
        </template>
      </van-search>
    </div>
    <div class="m-tabs">
      <div class="m-t-l">
        <van-tabs v-model:active="active" color="#1989fa" swipe-threshold="3" @change="searchTab">
          <van-tab title="全部会员" name="" key="0"></van-tab>
          <van-tab :title="grade.name" v-for="grade in membersGrade" :key="grade.id" :name="grade.id"></van-tab>
        </van-tabs>
      </div>
      <div class="m-t-r" v-if="roleTrue">
        <router-link :to="{ name: 'AdminMemberCreate' }"><van-button type="primary" size="small" class="h-r">添加会员</van-button></router-link>
      </div>
    </div>
    <div class="m-list">
      <div class="m-l-m" v-for="m in members" :key="m.id">
        <div class="m-l">
          <template v-if="m.member"><img :src="m.member.avatar" /></template>
          <template v-else><img src="../../../assets/images/user_def.png" /></template>
        </div>
        <div class="m-m">
          <h3 v-if="m.member">{{m.member.nickname}}</h3>
          <p>姓  名：{{m.real_name}}<span class="m-m-g"><template v-if="m.gender == 1">男</template><template v-else>女</template></span>{{m.age}}岁</p>
          <p v-if="roleId != 11">手机号：{{m.phone}}</p>
        </div>
        <div class="m-r">
          <router-link :to="{ name: 'AdminMemberDetails', query: {id: m.id} }"><van-button type="primary" size="mini" class="h-r right">详 情</van-button></router-link>
        </div>
      </div>
    </div>
    <van-popup v-model:show="show" position="right" :style="{ height: '100%' }">
      <div style="width: 320px;">服务记录</div>
    </van-popup>
  </div>
</template>

<script>
  import { IndexBar, IndexAnchor, Search, Image as VanImage, Badge, Popover, Popup, Tab, Tabs } from 'vant';

  export default {
    components: {
      [IndexBar.name]: IndexBar,
      [IndexAnchor.name]: IndexAnchor,
      [Search.name]: Search,
      [VanImage.name]: VanImage,
      [Badge.name]: Badge,
      [Popover.name]: Popover,
      [Popup.name]: Popup,
      [Tab.name]: Tab,
      [Tabs.name]: Tabs
    },
    name: 'AdminManage',
    data () {
      return {
        keyWord: '',
        show: false,
        active: 0,
        members: [],
        membersGrade: [],
        gradeId: '',
        roleTrue: false
      }
    },
    computed:{
      roleId(){
        return this.$store.state.roleId
      }
    },
    created () {
      if(this.roleId == 9 || this.roleId == 10){
        this.roleTrue = true
      }
      this.getMembersGrade();
      this.getMembersStore();
    },
    methods: {
      searchTab (e) {
        this.gradeId = e
        this.getMembersStore()
      },
      getMembersStore() {
        this.$api.get('membersStore', {realName:this.keyWord, membersGradeId:this.gradeId, pageSize: 100}, r=>{
          this.members = r.result.data;
        })
      },
      getMembersGrade() {
        this.$api.get('membersGrade', {pageSize: 100}, r=>{
          this.membersGrade = r.result.data;
        })
      }
    }
  }
</script>
<style scoped>
  .h-search{
    overflow: hidden;
    background: #ffffff;
  }
  .m-tabs{
    overflow: hidden;
    background: #ffffff;
  }
  .m-tabs .m-t-l{
    float: left;
    width: 75%;
  }
  .m-tabs .m-t-r{
    float: right;
    width: 20%;
    text-align: right;
    margin-top:6px;
    margin-right: 10px;
  }
  .m-list{
    overflow: hidden;
  }
  .m-list .m-l-m{
    overflow: hidden;
    background: #ffffff;
    margin-top: 10px;
    padding:15px;
  }
  .m-list .m-l-m .m-l{
    float: left;
    width: 15%;
    margin-right: 3%;
  }
  .m-list .m-l-m .m-l img{
    width: 100%;
  }
  .m-list .m-l-m .m-m{
    float: left;
    width: 60%;
  }
  .m-list .m-l-m .m-m .m-m-g{
    margin: 0 20px;
  }
  .m-list .m-l-m .m-m h3{
    margin: 0;
    font-size: 14px;
    color:#666666;
  }
  .m-list .m-l-m .m-m p{
    font-size: 13px;
    margin: 0;
    color:#7e7f81;
  }
  .m-list .m-l-m .m-r{
    float: right;
    width: 20%;
  }
</style>
