import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/index/index.vue'
import Project from '../views/project/index.vue'
import ProjectDetails from '../views/project/details.vue'
import Activity from '../views/activity/index.vue'
import ActivityDetails from '../views/activity/details.vue'
import User from '../views/user/index.vue'
import Subscribe from '../views/subscribe/index.vue'
import SubscribeStaff from '../views/subscribe/staff.vue'
import BindingWeixin from '../views/binding/weixin.vue'
import PaymentIndex from '../views/payment/index.vue'
import PaymentSucceed from '../views/payment/succeed.vue'
import OrderList from '../views/users/order_list/index.vue'
import OrderDetails from '../views/users/order_details/index.vue'
import UsersProject from '../views/users/project/index.vue'
import MessageYear from '../views/message/year.vue'
import UsersSetting from '../views/users/setting/index.vue'
import UsersEdit from '../views/users/setting/edit.vue'
import UsersSecurity from '../views/users/setting/security.vue'
import UsersSubscribe from '../views/users/subscribe/index.vue'
import UsersProfit from '../views/users/profit/index.vue'

/*商户管理*/
import AdminIndex from '../views/admin/index/index.vue'
import AdminUser from '../views/admin/user/index.vue'
import AdminUserRecord from '../views/admin/user/serviceRecord.vue'
import AdminManage from '../views/admin/manage/index.vue'
import AdminManagePoster from '../views/admin/manage/poster.vue'
import AdminManageStore from '../views/admin/manage/store.vue'
import AdminStatistics from '../views/admin/statistics/index.vue'
import AdminMember from '../views/admin/member/index.vue'
import AdminMemberDetails from '../views/admin/member/details.vue'
import AdminMemberCreate from '../views/admin/member/create_edit.vue'
import AdminSubscribe from '../views/admin/subscribe/index.vue'
import AdminEmpty from '../views/admin/empty/index.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      title: '首页',
      show: true,
      requireAuth: true,
    },
    component: Home
  },
  {
    path: '/binding/weixin',
    name: 'bindingWeixin',
    meta: {
      title: '绑定微信',
      show: true,
      requireAuth: true,
    },
    component: BindingWeixin
  },
  {
    path: '/project/index',
    name: 'project',
    meta: { title: '项目' },
    component: Project
  },
  {
    path: '/project/details',
    name: 'projectDetails',
    meta: {
      title: '项目详情',
      show: true,
      requireAuth: true
    },
    component: ProjectDetails
  },
  {
    path: '/activity/index',
    name: 'activity',
    meta: {
      title: '活动',
      show: true,
      requireAuth: true,
    },
    component: Activity
  },
  {
    path: '/activity/details',
    name: 'activityDetails',
    meta: {
      title: '活动详情',
      show: true,
      requireAuth: true,
    },
    component: ActivityDetails
  },
  {
    path: '/subscribe/index',
    name: 'subscribe',
    meta: {
      title: '预约',
      show: true,
      requireAuth: true,
    },
    component: Subscribe
  },
  {
    path: '/subscribe/staff',
    name: 'subscribeStaff',
    meta: {
      title: '养发师',
      show: true,
      requireAuth: true,
    },
    component: SubscribeStaff
  },
  {
    path: '/user/index',
    name: 'user',
    meta: {
      title: '我的',
      show: true,
      requireAuth: true,
    },
    component: User
  },
  {
    path: '/payment/index',
    name: 'paymentIndex',
    meta: {
      title: '支付',
      show: true,
      requireAuth: true,
    },
    component: PaymentIndex
  },
  {
    path: '/payment/succeed',
    name: 'paymentSucceed',
    meta: {
      title: '支付成功',
      show: true,
      requireAuth: true,
    },
    component: PaymentSucceed
  },
  {
    path: '/users/order',
    name: 'OrderList',
    component: OrderList,
    meta: {
      title: '全部订单',
      show: true,
      requireAuth: true,
    },
  },
  {
    path: '/users/order_details',
    name: 'OrderDetails',
    component: OrderDetails,
    meta: {
      title: '订单详情',
      show: true,
      requireAuth: true,
    },
  },
  {
    path: '/users/project',
    name: 'UsersProject',
    component: UsersProject,
    meta: {
      title: '我的服务',
      show: true,
      requireAuth: true,
    },
  },
  {
    path: '/users/subscribe',
    name: 'UsersSubscribe',
    component: UsersSubscribe,
    meta: {
      title: '我的预约',
      show: true,
      requireAuth: true,
    },
  },
  {
    path: '/users/profit',
    name: 'UsersProfit',
    component: UsersProfit,
    meta: {
      title: '我的收益',
      show: true,
      requireAuth: true,
    },
  },
  {
    path: '/users/setting',
    name: 'UsersSetting',
    component: UsersSetting,
    meta: {
      title: '设置',
      show: true,
      requireAuth: true,
    },
  },
  {
    path: '/users/edit',
    name: 'UsersEdit',
    component: UsersEdit,
    meta: {
      title: '个人资料',
      show: true,
      requireAuth: true,
    },
  },
  {
    path: '/users/security',
    name: 'UsersSecurity',
    component: UsersSecurity,
    meta: {
      title: '账户安全',
      show: true,
      requireAuth: true,
    },
  },
  {
    path: '/message/year',
    name: 'MessageYear',
    component: MessageYear,
    meta: {
      title: '广东绀发2021年会盛典',
      show: true,
      requireAuth: true,
    },
  },
  {
    path: '/admin/empty',
    name: 'AdminEmpty',
    component: AdminEmpty,
    meta: {
      title: '未开通',
    },
  },
  {
    path: '/admin',
    name: 'AdminIndex',
    component: AdminIndex,
    redirect: '/admin/statistics',
    meta: {
      title: '主页',
      show: true,
      requireAuth: false,
      isAdmin: false
    },
    children: [
      {
        path: 'statistics',
        name: 'AdminStatistics',
        component: AdminStatistics,
        meta: {
          title: '业绩',
          show: true,
          requireAuth: true,
          isAdmin: true
        },
      },
      {
        path: 'user',
        name: 'AdminUser',
        component: AdminUser,
        meta: {
          title: '我的',
          show: true,
          requireAuth: true,
          isAdmin: true
        },
      },
      {
        path: 'user/record',
        name: 'AdminUserRecord',
        component: AdminUserRecord,
        meta: {
          title: '服务记录',
          show: true,
          requireAuth: true,
          isAdmin: true
        },
      },
      {
        path: 'manage',
        name: 'AdminManage',
        component: AdminManage,
        meta: {
          title: '管理',
          show: true,
          requireAuth: true,
          isAdmin: true
        },
      },
      {
        path: 'manage/poster',
        name: 'AdminManagePoster',
        component: AdminManagePoster,
        meta: {
          title: '推广海报',
          show: true,
          requireAuth: true,
          isAdmin: true
        },
      },
      {
        path: 'manage/store',
        name: 'AdminManageStore',
        component: AdminManageStore,
        meta: {
          title: '门店设置',
          show: true,
          requireAuth: true,
          isAdmin: true
        },
      },
      {
        path: 'member',
        name: 'AdminMember',
        component: AdminMember,
        meta: {
          title: '会员',
          show: true,
          requireAuth: true,
          isAdmin: true
        },
      },
      {
        path: 'member/details',
        name: 'AdminMemberDetails',
        component: AdminMemberDetails,
        meta: {
          title: '会员详情',
          show: true,
          requireAuth: true,
          isAdmin: true
        },
      },
      {
        path: 'member/create',
        name: 'AdminMemberCreate',
        component: AdminMemberCreate,
        meta: {
          title: '新建会员',
          show: true,
          requireAuth: true,
          isAdmin: true
        },
      },
      {
        path: 'subscribe',
        name: 'AdminSubscribe',
        component: AdminSubscribe,
        meta: {
          title: '预约',
          show: true,
          requireAuth: true,
          isAdmin: true
        },
      }
    ]
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router
