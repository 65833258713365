<template>
    <!--
    <van-nav-bar
        :title="projects.name"
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
    />
    -->
    <div class="banner">
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" lazy-render>
            <van-swipe-item v-for="(img, index) in projects.slider_image" :key="index"><img :src="img.url" /></van-swipe-item>
        </van-swipe>
    </div>
    <div v-show="show">
        <div class="goods-p-t">
            <div class="goods-price"><span class="p-1">￥{{ projects.price }}</span><span class="p-2">{{ projects.cate_name }} </span><span class="p-3">销量：{{ projects.sum_sales }}</span></div>
            <h3 class="g-title">{{ projects.name }}</h3>
            <div class="g-inventory">
                <span class="left">服务次数：{{ projects.service_number }}</span>
<!--                <span class="right">库存：{{ projects.stock }}</span>-->
            </div>
        </div>
        <div class="goods-show">
            <h3 class="h3">项目详情</h3>
            <div class="content-m" v-html="projects.content"></div>
        </div>

        <Footer />
    </div>
</template>

<script>
    import { NavBar, Swipe, SwipeItem } from 'vant';
    import wx from 'weixin-jsapi';

    export default {
        components: {
            [NavBar.name]: NavBar,
            [Swipe.name]: Swipe,
            [SwipeItem.name]: SwipeItem
        },
        name: "project",
        data() {
            return {
                show: false,
                projects: [],
                shareLink: '',
            }
        },
        created () {
            this.getProject();
            this.shareLink = window.location.href;
            this.getJssdkConfig("onMenuShareTimeline,checkJsApi,onMenuShareAppMessage",false,false,this.shareLink);
        },
        methods: {
            getProject() {
                let self = this
                this.$api.get('storeProject/details', {id: this.$route.query.id}, r=>{
                    this.projects = r.data;
                    this.projects.cate_name = this.projects.category.name
                    this.projects.sum_sales = this.projects.sales_initial + this.projects.sales
                    this.show = true
                    self.getJssdkConfig("onMenuShareTimeline,checkJsApi,onMenuShareAppMessage",false,false,this.shareLink);
                })
            },
            onClickLeft() {
                this.$router.push({name: 'project', query: {mid: sessionStorage.getItem('mid') }})
            },
            getJssdkConfig(apis,debug,json,link){
                let self = this
                this.$api.get('jssdkConfig', {apis:apis, debug:debug, json:json, url: link}, r=>{
                    let config = r.data;
                    wx.config(config)
                    wx.ready(function() {
                        let shareData = {
                            title: self.projects.share_title != '' ? self.projects.share_title : self.projects.name,
                            desc: self.projects.share_brief,
                            link: self.shareLink,
                            imgUrl: self.projects.image,
                            success: function () {
                                // self.$api.post('activity/share', {id: self.projects.id})
                            }
                        }
                        wx.showOptionMenu()
                        wx.onMenuShareAppMessage(shareData)
                        wx.onMenuShareTimeline(shareData)
                    })
                })
            }
        }
    }
</script>

<style scoped>
    .banner{
        overflow: hidden;
    }
    .my-swipe .van-swipe-item {
        color: #ffffff;
        font-size: 20px;
        line-height: 150px;
        text-align: center;
    }
    .banner img{
        display: block;
        width: 100%;
    }
    .goods-imgs img{
        display: block;
    }
    .goods-p-t{
        padding:15px;
        overflow: hidden;
        background: #ffffff;
    }
    .goods-p-t .goods-price{
        overflow: hidden;
    }
    .goods-p-t .goods-price span{
        float: left;
        margin-right: 10px;
    }
    .goods-p-t .goods-price .p-1{
        color:#EC0018;
        font-size: 16px;
        font-weight: bold;
    }
    .goods-p-t .goods-price .p-2{
        color:#9E9D9F;
        font-size: 12px;
        margin-top: 3px;
    }
    .goods-p-t .goods-price .p-2 em{
        font-style: normal;
        text-decoration:line-through;
    }
    .goods-p-t .goods-price .p-3{
        float: right;
        margin-right: 0;
    }
    .goods-p-t .g-title{
        font-size: 14px;
        margin: 0;
        padding-top: 10px;
    }
    .goods-p-t .g-inventory{
        font-size: 12px;
        color:#9E9D9F;
        margin-top: 10px;
    }
    .goods-show{
        margin-top:10px;
        overflow: hidden;
        background: #ffffff;
    }
    .goods-show .h3{
        padding:0 15px;
        font-weight: normal;
        font-size: 15px;
    }
    .goods-show .content-m >>> img{
        display: block;
        max-width: 100% !important;
    }
</style>
