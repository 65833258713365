<template>
  <div>
    <van-form @submit="onSubmit">
      <van-field
        v-model="real_name"
        name="real_name"
        label="姓名"
        placeholder="姓名"
        :rules="[{ required: true, message: '请填写姓名' }]"
      />
      <van-field
        v-model="phone"
        name="phone"
        label="手机号"
        placeholder="手机号"
        readonly
      />
      <van-field
        v-model="nickname"
        name="nickname"
        label="昵称"
        placeholder="昵称"
        readonly
      />
      <van-field
        v-model="avatar"
        name="avatar"
        label="图像">
        <template #input>
          <van-image
            width="78"
            height="78"
            :src="avatar"
          />
<!--          <van-uploader :after-read="afterRead" class="up-avatar" />-->
        </template>
      </van-field>
      <van-field name="gender" label="性别">
        <template #input>
          <van-radio-group v-model="gender" direction="horizontal">
            <van-radio name="1">男</van-radio>
            <van-radio name="2">女</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <div class="save-btn">
        <van-button round block type="danger" native-type="submit">保 存</van-button>
      </div>
    </van-form>

    <Footer />
    <Floating />
    <wxNoShare />
  </div>
</template>

<script>
  import { Form, Button, Uploader, RadioGroup, Radio,Image as VanImage, Cell, CellGroup } from 'vant'

  export default {
    components: {
      [Form.name]: Form,
      [Button.name]: Button,
      [Uploader.name]: Uploader,
      [RadioGroup.name]: RadioGroup,
      [Radio.name]: Radio,
      [VanImage.name]: VanImage,
      [Cell.name]: Cell,
      [CellGroup.name]: CellGroup,
    },
    name: 'edit',
    data() {
      return {
        real_name: '',
        phone: '',
        nickname: '',
        gender: '',
        avatar: '',
        avatarImg: '',
      }
    },
    created() {
      this.getUser()
    },
    methods: {
      onSubmit(values) {
        values.avatar = this.avatarImg
        this.$api.post('user/update', values, r=>{
          if(r.status == 200){
            this.$toast('保存成功')
          }else{
            this.$toast('保存失败')
          }
        })
      },
      getUser() {
        this.$api.get('user', null, r=>{
          let data = r.data
          this.real_name = data.real_name
          this.gender = data.gender
          this.phone = data.phone
          this.nickname = data.nickname
          this.avatar = data.avatar
          this.avatarImg = data.avatar
        })
      },
      afterRead(file) {
        var formdata = new FormData();
        formdata.append("pics", file.file);
        formdata.append("type", 'avatar');
        this.$api.post('upload/image', formdata, r=>{
          this.avatar = r.data.url+'?x-oss-process=style/h132'
          this.avatarImg = this.avatar
        })
      },
    },
  }
</script>

<style scoped>
  .save-btn{
    margin: 15px;
  }
  .up-avatar{
    height: 80px;
    margin-left: 10px;
  }
</style>
