<template>
  <div>
    <div class="user-top">
      <img src="../../assets/images/bg_02.jpg" class="img" />
      <div class="user-t-m">
        <div class="user-t-m-1"><img v-if="userInfo.avatar" :src="userInfo.avatar" /><img v-else src="../../assets/images/user_def.png" /></div>
        <div class="user-t-m-2">
            <span v-if="userInfo">
              <p class="u-name" style="margin-top: 0">{{ userInfo.nickname }}</p>
              <div class="u-in" v-if="userInfo.is_member_store == 1">{{userInfo.member_store.real_name}}<span class="u-phone">{{userInfo.member_store.phone}}</span></div>
              <div v-if="userInfo.is_member_store == 1 && userInfo.member_store.member_grade" @click="gradePopup" class="grade-info">
                <span>{{userInfo.member_store.member_grade.name}}</span>
                <img src="../../assets/images/ico_01.png" width="25" />
              </div>
            </span>
          <div v-else class="user-login">会员登录</div>
        </div>
      </div>
      <div class="user-t-r">
        <div class="user-t-r-w">
          <router-link :to="{ name: 'UsersSetting'}">
              <span>
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-tubiao112"></use>
                </svg>
              </span>
          </router-link>
        </div>
      </div>
    </div>
    <div class="u-fw">
      <van-row>
        <van-col span="8" class="f-li" @click="toMyUrl('UsersSubscribe')">
          <van-icon name="todo-list-o" class="icon" />
          <span>我的预约</span>
        </van-col>
        <van-col span="8" class="f-li" @click="toMyUrl('UsersProject')">
          <van-icon name="orders-o" class="icon" />
          <span>我的服务</span>
        </van-col>
        <van-col span="8" class="f-li" @click="toMyUrl('UsersProfit')">
          <van-icon name="cash-back-record" class="icon" />
          <span>我的收益</span>
        </van-col>
      </van-row>
    </div>
    <div class="u-orders">
      <h3 class="h3">我的订单</h3>
      <div class="u-o-m">
        <ul>
          <li>
            <router-link :to="{ name: 'OrderList', query: { title: '未付款' }}">
              <van-badge :content="statistics.stay_pay_count" max="9" :show-zero="false">
                  <span>
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#icon-daifukuan"></use>
                    </svg>
                  </span>
                <p>未付款</p>
              </van-badge>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'OrderList', query: { title: '已付款' }}">
              <van-badge :content="statistics.send_pay_count" max="9" :show-zero="false">
                  <span>
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#icon-icon-test"></use>
                    </svg>
                  </span>
                <p>已付款</p>
              </van-badge>
            </router-link>
          </li>
          <li>
            <router-link :to="{ name: 'OrderList', query: { title: '已完成' }}">
              <van-badge :content="statistics.send_put_count" max="9" :show-zero="false">
                  <span>
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#icon-yifahuo"></use>
                    </svg>
                  </span>
                <p>已完成</p>
              </van-badge>
            </router-link>
          </li>
          <li>
            <router-link to="/users/order">
              <span>
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-dingdan1"></use>
                </svg>
              </span>
              <p>全部</p>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <!--
    <div class="user-a">
      <h3 class="h3"><img src="../../assets/images/ico_s_01.png" width="20" class="img" />账户总分</h3>
      <div class="u-b">
        <div class="u-b-li">
          <span class="price">{{ user.account }}</span>
          <span class="info">账户</span>
        </div>
        <div class="u-b-li">
          <span class="price">{{ user.freeze_account }}</span>
          <span class="info">未结算奖励</span>
        </div>
      </div>
      <div class="u-c">
        <ul>
          <li><router-link to="/users/income?type=1">结算明细</router-link></li>
          <li><router-link to="/users/income?type=2">收支明细</router-link></li>
        </ul>
      </div>
    </div>
    -->
    <div class="user-m-li">
      <van-cell title="优惠券" is-link />
      <van-cell title="卡包" is-link />

      <!--
      <van-collapse v-model="activeNames" v-if="mid == 'GPxbgEWN'">
        <van-collapse-item title="年会报名" name="1">
          <div v-if="years.real_name != ''">
            <van-row gutter="20">
              <van-col span="8">姓名</van-col>
              <van-col span="8">手机号</van-col>
              <van-col span="8">状态</van-col>
            </van-row>
            <van-row gutter="20">
              <van-col span="8">{{ years.real_name }}</van-col>
              <van-col span="8">{{ years.phone }}</van-col>
              <van-col span="8"><span v-if="years.status == 1">报名成功</span><span v-else>审核中</span></van-col>
            </van-row>
          </div>
        </van-collapse-item>
      </van-collapse>
      -->
    </div>
    <van-popup v-model:show="gradeShow">
      <div class="g-d-content">{{userInfo.member_store.member_grade.discount}}</div>
    </van-popup>
    <Footer />
    <WxNoShare />
  </div>
</template>

<script>
  import { Icon, Badge, Popup, Dialog, Cell, CellGroup, Collapse, CollapseItem } from 'vant'

  export default {
    components: {
      [Icon.name]: Icon,
      [Badge.name]: Badge,
      [Popup.name]: Popup,
      [Cell.name]: Cell,
      [CellGroup.name]: CellGroup,
      [Collapse.name]: Collapse,
      [CollapseItem.name]: CollapseItem,
      [Dialog.Component.name]: Dialog.Component,
    },
    name: 'index',
    data() {
      return {
        show: false,
        gradeShow: false,
        activeNames: [],
        userInfo: [],
        statistics: {},
        years: {real_name: '', phone:'', status: 0},
        mid: ''
      }
    },
    created () {
      this.mid = sessionStorage.getItem('mid');
      this.getUser();
      this.getOrderData();
      this.getYearDetails();
    },
    methods: {
      getYearDetails() {
        this.$api.get('year/details', null, r=>{
          if(r.data.result != null){
            this.years = r.data.result;
          }
        })
      },
      getUser() {
        this.$api.get('user', {mid: sessionStorage.getItem('mid')}, r=>{
          this.userInfo = r.data;
        })
      },
      getOrderData() {
        this.$api.get('storesOrders/data', null, r=>{
          this.statistics = r.data;
        })
      },
      toMyUrl(name) {
        this.$router.push({name: name})
      },
      gradePopup() {
        this.gradeShow = true
      }
    }
  }
</script>

<style scoped>
  .u-orders{
    background: #ffffff;
    padding:10px 0;
    overflow: hidden;
    margin-top:10px;
  }
  .u-orders .h3{
    margin: 0;
    font-size: 14px;
    font-weight: normal;
    padding:0 15px 10px;
    border-bottom: 1px solid #eff1f3;
  }
  .u-orders .h3 .u-o-more{
    float: right;
    font-size: 15px;
  }
  .u-orders .h3 .u-o-more .ico{
    font-size: 20px;
    float: right;
  }
  .u-orders .u-o-m{

  }
  .u-orders .u-o-m ul li{
    float: left;
    width: 25%;
    text-align: center;
  }
  .u-orders .u-o-m ul li a{
    display: block;
    padding:15px 0 5px;
  }
  .u-orders .u-o-m ul li span{
    font-size: 30px;
  }
  .u-orders .u-o-m ul li p{
    margin: 0;
    font-size: 14px;
  }

  .user-a{
    margin-top: 10px;
    overflow: hidden;
    padding:10px 15px;
    background: #ffffff;
  }
  .user-a .h3{
    margin: 0;
    font-size: 14px;
    font-weight: normal;
  }
  .user-a .h3 .img{
    float: left;
    margin-right: 5px;
  }
  .user-a .u-b{
    overflow: hidden;
    padding:15px 0;
  }
  .user-a .u-b .u-b-li{
    width: 41%;
    float: left;
    border-right: 1px solid #DCDEE0;
    padding:0 4%;
  }
  .user-a .u-b .u-b-li:last-child{
    border-right: none;
  }
  .user-a .u-b .u-b-li span{
    display: block;
  }
  .user-a .u-b .u-b-li .price{

  }
  .user-a .u-b .u-b-li .info{
    color:#969799;
    font-size: 12px;
    margin-top: 3px;
  }
  .user-a .u-c{
    overflow: hidden;
  }
  .user-a .u-c ul li{
    float: left;
    background: #FFFBE8;
    border-radius: 3px;
    width: 46%;
    margin:2%;
  }
  .user-a .u-c ul li a{
    color:#ed6a0c;
    display: block;
    text-align: center;
    padding:3% 0;
    font-size: 14px;
  }
  .user-b{
    margin-top: 10px;
    padding:15px 0;
    overflow: hidden;
    background: #ffffff;
  }
  .user-b ul li{
    float: left;
    width: 25%;
    text-align: center;
  }
  .user-b ul li a img{
    width: 60%;
  }
  .user-b ul li a span{
    display: block;
    font-size: 14px;
  }
  .u-fw{
    overflow: hidden;
    background: #ffffff;
    font-size: 15px;
    text-align: center;
  }
  .u-fw .f-li{
    padding:20px 0;
    border-right: 1px solid #e6e8eb;
  }
  .u-fw .f-li:last-child{
    border-right: none;
  }
  .u-fw .f-li .icon{
    font-size: 28px;
  }
  .u-fw .f-li span{
    display: block;
    margin-top:3px;
  }
  .user-m-li{
    margin-top: 10px;
  }
  .g-d-content{
    padding:20px;
  }
  .grade-info{
    color:#EABE81;
  }
  .grade-info span{
    display: inline-block;
    font-size: 14px;
    margin-top:3px;
    margin-right: 4px;
    float: left;
  }
  .grade-info img{
    margin-top: 5px;
    float: left;
  }
</style>
