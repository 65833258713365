<template>
  <div class="record">
    <van-nav-bar
          title="服务记录"
          left-text="返回"
          left-arrow
          @click-left="onClickLeft"
    />
    <div class="r-li" v-for="r in list" :key="r.id">
      <div class="u-info">
        <van-row>
          <van-col span="12">日期：{{r.created_at}}</van-col>
          <van-col span="8">会员: {{r.staff_name}}</van-col>
          <van-col span="4"><van-tag type="primary" class="right">第{{r.frequency}}次</van-tag></van-col>
        </van-row>
        <van-row>
          <van-col span="12">养发师: {{r.staff_name}}</van-col>
          <van-col span="12">顾客签名: {{r.autograph}}</van-col>
        </van-row>
        <van-row>
          <van-col span="24">脱发情况: {{r.service_situation}}</van-col>
        </van-row>
        <van-row>
          <van-col span="24">白发情况: {{r.bf_situation}}</van-col>
        </van-row>
        <van-row v-if="r.remarks">
          <van-col span="24">备注: {{r.remarks}}</van-col>
        </van-row>
        <div class="u-i-pic">
          <img v-for="p in r.photo" :key="p.id" :src="p.url" @click="showPhoto(p.url)" />
        </div>
      </div>
    </div>
    <van-popup v-model:show="showConsult"><div class="consult_pic"><img :src="picUrl" /></div></van-popup>
  </div>
</template>

<script>
  import { NavBar, Tag, Image as VanImage, Popup } from 'vant';

  export default {
    components: {
      [NavBar .name]: NavBar ,
      [Tag .name]: Tag ,
      [VanImage .name]: VanImage ,
      [Popup .name]: Popup ,
    },
    name: 'Record',
    data () {
      return {
        list: [],
        picUrl: '',
        showConsult: false
      }
    },
    computed:{
      roleId(){
        return this.$store.state.roleId
      }
    },
    created () {
      this.getRecord()
    },
    methods: {
      getRecord() {
        this.$api.get('membersStore/userServiceRecord', null, r=>{
          this.list = r.result;
        })
      },
      onClickLeft() {
        this.$router.push('/admin/user')
      },
      showPhoto(url) {
        this.picUrl = url
        this.showConsult = true
      }
    }
  }
</script>
<style scoped>
  .record{
    overflow: hidden;
  }
  .record .r-li{
    overflow: hidden;
    margin-bottom: 10px;
    background: #ffffff;
    padding:10px;
  }
  .record .r-li .u-info{
    font-size: 12px;
    overflow: hidden;
  }
  .record .r-li .u-info .van-row{
    margin: 3px 0;
  }
  .record .r-li .u-info .u-i-pic{
    overflow: hidden;
  }
  .record .r-li .u-info .u-i-pic img{
    float: left;
    width: 60px;
    height: 60px;
    margin: 0 3px;
  }
  .consult_pic{
    overflow: hidden;
  }
  .consult_pic img{
    width: 310px;
  }
</style>
