<template>
  <div class="statistics">
    <div class="store-title">{{storeInfo.name}}</div>
    <div class="store-data">
      <div class="s-title">{{yjTitle}}业绩统计</div>
      <van-tabs v-if="roleId == 9 || roleId == 10" v-model:active="active" color="#ffffff">
        <van-tab title="今日">
          <div class="s-m">
            <ul>
              <li @click="getStatisticsList(0, 0)"><span>{{sumData.day.member_count}}</span><p>新增会员</p></li>
              <li><span>{{sumData.day.order_count}}</span><p>新增订单</p></li>
              <li @click="getStatisticsList(0, 2)"><span>{{sumData.day.message_count}}</span><p>新增预约</p></li>
              <li @click="getStatisticsList(0, 3)"><span>{{sumData.day.project_account}}</span><p>项目金额</p></li>
              <li><span>{{sumData.day.marketing_account}}</span><p>营销金额</p></li>
            </ul>
          </div>
        </van-tab>
        <van-tab title="本月">
          <div class="s-m">
            <ul>
              <li @click="getStatisticsList(1, 0)"><span>{{sumData.month.member_count}}</span><p>新增会员</p></li>
              <li><span>{{sumData.month.order_count}}</span><p>新增订单</p></li>
              <li @click="getStatisticsList(1, 2)"><span>{{sumData.month.message_count}}</span><p>新增预约</p></li>
              <li @click="getStatisticsList(1, 3)"><span>{{sumData.month.project_account}}</span><p>项目金额</p></li>
              <li><span>{{sumData.month.marketing_account}}</span><p>营销金额</p></li>
            </ul>
          </div>
        </van-tab>
        <van-tab title="今年">
          <div class="s-m">
            <ul>
              <li><span>{{sumData.year.member_count}}</span><p>新增会员</p></li>
              <li><span>{{sumData.year.order_count}}</span><p>新增订单</p></li>
              <li><span>{{sumData.year.message_count}}</span><p>新增预约</p></li>
              <li><span>{{sumData.year.project_account}}</span><p>项目金额</p></li>
              <li><span>{{sumData.year.marketing_account}}</span><p>营销金额</p></li>
            </ul>
          </div>
        </van-tab>
        <van-tab title="总计">
          <div class="s-m">
            <ul>
              <li><span>{{sumData.marketing_account}}</span><p>营销账户</p></li>
              <li><span>{{sumData.order_count}}</span><p>订单数量</p></li>
              <li><span>{{sumData.extract_price}}</span><p>提现总金额</p></li>
              <li><span>{{sumData.project_account}}</span><p>项目总金额</p></li>
              <li><span>{{sumData.member_count}}</span><p>会员数量</p></li>
              <li><span>{{sumData.message_count}}</span><p>预约数量</p></li>
            </ul>
          </div>
        </van-tab>
      </van-tabs>
      <van-tabs v-else-if="roleId == 11" v-model:active="active" color="#ffffff">
        <van-tab title="今日">
          <div class="s-m">
            <ul>
              <li><span>{{staffStatistics.day.bill_count}}</span><p>开卡数量</p></li>
              <li><span>{{staffStatistics.day.service_count}}</span><p>服务数量</p></li>
<!--              <li><span>{{staffStatistics.day.project_account}}</span><p>项目金额</p></li>-->
              <li><span>{{staffStatistics.day.marketing_account}}</span><p>营销金额</p></li>
              <li><span>{{staffStatistics.day.commission_marketing_sum}}</span><p>营销提成</p></li>
              <li><span>{{staffStatistics.day.commission_project_sum}}</span><p>项目提成</p></li>
              <li><span>{{staffStatistics.day.commission_manual_sum}}</span><p>服务手工费</p></li>
            </ul>
          </div>
        </van-tab>
        <van-tab title="本月">
          <div class="s-m">
            <ul>
              <li><span>{{staffStatistics.month.bill_count}}</span><p>开卡数量</p></li>
              <li><span>{{staffStatistics.month.service_count}}</span><p>服务数量</p></li>
<!--              <li><span>{{staffStatistics.month.project_account}}</span><p>项目金额</p></li>-->
              <li><span>{{staffStatistics.month.marketing_account}}</span><p>营销金额</p></li>
              <li><span>{{staffStatistics.month.commission_marketing_sum}}</span><p>营销提成</p></li>
              <li><span>{{staffStatistics.month.commission_project_sum}}</span><p>项目提成</p></li>
              <li><span>{{staffStatistics.month.commission_manual_sum}}</span><p>服务手工费</p></li>
            </ul>
          </div>
        </van-tab>
        <!--
        <van-tab title="今年">
          <div class="s-m">
            <ul>
              <li><span>{{staffStatistics.year.bill_count}}</span><p>开卡数量</p></li>
              <li><span>{{staffStatistics.year.service_count}}</span><p>服务数量</p></li>
              <li><span>{{staffStatistics.year.project_account}}</span><p>项目金额</p></li>
              <li><span>{{staffStatistics.year.marketing_account}}</span><p>营销金额</p></li>
              <li><span>{{staffStatistics.year.commission_marketing_sum}}</span><p>营销提成</p></li>
              <li><span>{{staffStatistics.year.commission_project_sum}}</span><p>项目提成</p></li>
              <li><span>{{staffStatistics.year.commission_manual_sum}}</span><p>服务手工费</p></li>
            </ul>
          </div>
        </van-tab>
        <van-tab title="总计">
          <div class="s-m">
            <ul>
              <li><span>{{staffStatistics.whole.bill_count}}</span><p>开卡数量</p></li>
              <li><span>{{staffStatistics.whole.service_count}}</span><p>服务数量</p></li>
              <li><span>{{staffStatistics.whole.project_account}}</span><p>项目总金额</p></li>
              <li><span>{{staffStatistics.whole.marketing_account}}</span><p>营销总金额</p></li>
              <li><span>{{staffStatistics.whole.sum_order_account}}</span><p>销售总金额</p></li>
              <li><span>{{staffStatistics.whole.sum_order_account}}</span><p>清算金额</p></li>
            </ul>
          </div>
        </van-tab>
        -->
      </van-tabs>
    </div>
    <div class="statistics-ranking">
      <div class="s-title s-t">{{titleTwo}}</div>
      <van-tabs v-model:active="activeTwo" color="#fe786a">
        <van-tab title="今日">
          <div class="s-a">
            <div class="s-a-m">
              <van-row class="s-a-row">
                <van-col span="3">排名</van-col>
                <van-col span="5">图像</van-col>
                <van-col span="8">姓名</van-col>
                <van-col span="8">金额</van-col>
              </van-row>
              <van-row class="s-a-row" :key="index" v-for="(item, index) in rankList.day">
                <van-col span="3">{{ index + 1 }}</van-col>
                <van-col span="5"><img :src="item.avatar" class="img" v-if="item.avatar != null" /><img src="../../../assets/images/user_def.png" class="img" v-else /></van-col>
                <van-col span="8">{{ item.name }}</van-col>
                <van-col span="8">￥{{ item.total }}</van-col>
              </van-row>
            </div>
          </div>
        </van-tab>
        <van-tab title="本月">
          <div class="s-a">
            <div class="s-a-m">
              <van-row class="s-a-row">
                <van-col span="3">排名</van-col>
                <van-col span="5">图像</van-col>
                <van-col span="8">姓名</van-col>
                <van-col span="8">金额</van-col>
              </van-row>
              <van-row class="s-a-row" :key="index" v-for="(item, index) in rankList.month">
                <van-col span="3">{{ index + 1 }}</van-col>
                <van-col span="5"><img :src="item.avatar" class="img" v-if="item.avatar != null" /><img src="../../../assets/images/user_def.png" class="img" v-else /></van-col>
                <van-col span="8">{{ item.name }}</van-col>
                <van-col span="8">￥{{ item.total }}</van-col>
              </van-row>
            </div>
          </div>
        </van-tab>
        <van-tab title="本年">
          <div class="s-a">
            <div class="s-a-m">
              <van-row class="s-a-row">
                <van-col span="3">排名</van-col>
                <van-col span="5">图像</van-col>
                <van-col span="8">姓名</van-col>
                <van-col span="8">金额</van-col>
              </van-row>
              <van-row class="s-a-row" :key="index" v-for="(item, index) in rankList.year">
                <van-col span="3">{{ index + 1 }}</van-col>
                <van-col span="5"><img :src="item.avatar" class="img" v-if="item.avatar != null" /><img src="../../../assets/images/user_def.png" class="img" v-else /></van-col>
                <van-col span="8">{{ item.name }}</van-col>
                <van-col span="8">￥{{ item.total }}</van-col>
              </van-row>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <!--业绩记录-->
    <van-popup v-model:show="showRecord" position="bottom" :style="{ height: '70%' }" closeable>
      <div class="s-list" v-for="item in dataList" :key="item.id">
        <h3>{{item.title}}</h3>
        <p>{{item.label}}</p>
      </div>
    </van-popup>
  </div>
</template>

<script>
  import { Divider,Tab, Tabs,DropdownMenu, DropdownItem, Popup } from 'vant';

  export default {
    components: {
      [Divider.name]: Divider,
      [Tab.name]: Tab,
      [Tabs.name]: Tabs,
      [Popup.name]: Popup,
      [DropdownMenu.name]: DropdownMenu,
      [DropdownItem.name]: DropdownItem,
    },
    name: 'AdminStatistics',
    data () {
      return {
        active: 0,
        activeTwo: 0,
        sumData: { order_count: 0, extract_price: 0, marketing_account: 0, project_account: 0, member_count: 0, message_count: 0, day: {}, month: {}, year: {} },
        rankList: { day:[], month:[], year:[] },
        staffStatistics: { day:{}, month:{}, year:{}, whole:{} },
        storeInfo: { name: '' },
        yjTitle: '',
        titleTwo: '业绩排行',
        show: false,
        showRecord: false,
        dataList: []
      }
    },
    computed:{
      roleId(){
        return this.$store.state.roleId
      }
    },
    created () {
      this.yjTitle = this.roleId == 11 ? "个人" : ""
      this.titleTwo = this.roleId == 11 ? "收益排行" : "业绩排行"
      this.getStoreInfo();
      this.getEmployeeRanking();
      this.getStatistics();
      this.getStaffStatistics();
    },
    methods: {
      getStoreInfo() {
        this.$api.get('stores/show', null, r=>{
          this.storeInfo = r.result;
        })
      },
      getStatistics() {
        this.$api.get('stores/statistics', null, r=>{
          this.sumData = r.result;
        })
      },
      getStaffStatistics() {
        this.$api.get('stores/staffStatistics', null, r=>{
          this.staffStatistics = r.result
        })
      },
      getEmployeeRanking() {
        this.$api.get('stores/employeeRanking', {roleId:this.roleId}, r=>{
          this.rankList = r.result;
        })
      },
      getStatisticsList(type = 0, m = 0) {
        this.dataList = [];
        this.$api.get('stores/statisticsList', {type:type, queryType:m}, r=>{
          this.dataList = r.result;
          this.showRecord = true;
        })
      }
    }
  }
</script>
<style scoped>
  .statistics{
    background: url(../../../assets/images/admin/bg_01.jpg) no-repeat #f4f4f4;
    background-size: 100%;
    overflow: hidden;
    padding:15px;
  }
  .statistics .store-title{
    font-size: 27px;
    font-weight: bold;
    color:#ffffff;
    margin:10px 0 0 10px;
  }
  .statistics .store-data{
    overflow: hidden;
    background: url(../../../assets/images/admin/bg_01.png) no-repeat center;
    background-size: 107%;
    margin-top: 50px;
    height: 170px;
    width: 100%;
    padding:20px 0;
    color:#ffffff;
  }
  .statistics .store-data .s-title{
    font-size: 14px;
    font-weight: bold;
    padding: 0 15px 5px;
  }
  .statistics .store-data >>> .van-tabs--line .van-tabs__wrap{
    height: 30px;
  }
  .statistics .store-data >>> .van-tabs__nav{
    background: none;
    color:#ffffff;
  }
  .statistics .store-data >>> .van-tabs__nav--line{
    height: 30px;
    line-height: 30px;
  }
  .statistics .store-data >>> .van-tab{
    color:#ffffff;
  }
  .statistics .s-m{
    overflow: hidden;
    margin-top: 5px;
  }
  .statistics .s-m ul li{
    float: left;
    width: 25%;
    text-align: center;
    margin-top: 10px;
    font-size: 13px;
  }
  .statistics .s-m ul li span{
    display: block;
    font-size: 14px;
    font-weight: bold;
  }
  .statistics .s-m ul li p{
    margin: 0;
    font-size: 12px;
  }
  .statistics .statistics-ranking{
    background: #ffffff;
    overflow: hidden;
    border-radius:5px;
    padding:10px 15px;
  }
  .statistics .statistics-ranking .s-t{
    color:#1d1d1d;
    font-size: 14px;
    font-weight: bold;
  }
  .statistics .statistics-ranking >>> .van-tabs--line .van-tabs__wrap{
    height: 30px;
    margin: 8px 0;
  }
  .statistics .statistics-ranking >>> .van-tabs__nav--line{
    height: 30px;
    line-height: 30px;
  }
  .statistics .statistics-ranking .s-a{
    overflow: hidden;
  }
  .statistics .statistics-ranking .s-a .s-a-row{
    line-height: 40px;
    text-align: center;
  }
  .statistics .statistics-ranking .s-a .s-a-row .img{
    height: 40px;
    width: 40px;
    border-radius: 30px;
  }
  .s-list{
    overflow: hidden;
    padding: 10px;
    border-bottom: 1px solid #f4f4f4;
  }
  .s-list h3{
    margin: 0;
  }
  .s-list p{
    margin-top:0;
  }
</style>
