<template>
    <div>
        <van-nav-bar
                title="我的收益"
                left-text="返回"
                left-arrow
                @click-left="onClickLeft"
        />
        <div class="profit-main">
            <van-cell-group>
                <van-cell v-for="p in profits" :key="p.id" :title="p.orders.order_no" :value="`+`+p.price" />
            </van-cell-group>
        </div>
        <Floating skipUrl="/user/index" />
        <wxNoShare />
    </div>
</template>

<script>
    import { Cell, CellGroup, NavBar } from 'vant'

    export default {
        components: {
            [Cell.name]: Cell,
            [CellGroup.name]: CellGroup,
            [NavBar.name]: NavBar,
        },
        name: 'UsersProfit',
        data() {
            return {
                profits: [],
                userInfo: {}
            }
        },
        created () {
            this.getProfit()
        },
        methods: {
            getProfit() {
                this.$api.get('storesMembers/profit', {mid: sessionStorage.getItem('mid'), type: 1, comm_type: 0}, r=>{
                    this.profits = r.data
                })
            },
            onClickLeft() {
                history.back()
            }
        }
    }
</script>

<style scoped>
    .profit-main{
        overflow: hidden;
    }
</style>
