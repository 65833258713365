<template>
  <div class="weixin">
    <div class="wx-info">
      <van-image
              round
              width="80px"
              height="80px"
              :src="user.avatar"
      />
      <p>昵称：{{user.nickname}}</p>
    </div>
    <div class="staff" v-if="details != null" v-show="show">
      <van-cell-group>
        <van-cell title="姓名" :value="details.name" />
        <van-cell title="手机号" :value="details.phone" />
        <van-cell title="职位" :value="details.occupation" />
      </van-cell-group>
      <van-button v-if="details.is_bind_status == 1" disabled round block type="danger" native-type="submit" class="s-btn">已绑定</van-button>
      <van-button v-else round block type="danger" native-type="submit" class="s-btn" @click="bindingWeixin">确认绑定</van-button>
    </div>
    <div v-else>
      <van-empty description="信息不存在" />
    </div>
  </div>
</template>

<script>
  import { Image as VanImage, Cell, CellGroup, Empty } from 'vant';

  export default {
    components: {
      [VanImage.name]: VanImage,
      [Cell.name]: Cell,
      [CellGroup.name]: CellGroup,
      [Empty.name]: Empty
    },
    name: 'bindingWeixin',
    data () {
      return {
        id: 0,
        show: false,
        user: {},
        details: {}
      }
    },
    created () {
      this.id = this.$route.query.id;
      this.user = this.$store.state;
      this.getDetails();
    },
    methods: {
      getDetails() {
        this.$api.get('storeProject/staffDetails', {id: this.id, mid: sessionStorage.getItem('mid')}, r => {
          this.details = r.data;
          this.details.occupation = this.details.roles[0].name;
          this.show = true;
        })
      },
      bindingWeixin() {
        this.$api.post('storesMembers/bindingWeixin', {id: this.id, mid: sessionStorage.getItem('mid')}, r => {
          if(r.status == 200){
            this.$toast({'message':r.message, onOpened:function(){
                if(r.status == 200){
                  window.location.reload()
                }
              }})
          }
        })
      }
    }
  }
</script>
<style scoped>
  .weixin{
    background: #ffffff;
    width: 100%;
    height: 100%;
    position: fixed;
  }
  .wx-info{
    text-align: center;
    margin-top: 50px;
  }
  .wx-info p{
    font-size: 14px;
  }
  .staff{
    padding:15px;
    overflow: hidden;
    background: #ffffff;
  }
  .staff .s-btn{
    margin-top: 30px;
  }
</style>
