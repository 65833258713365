<template>
    <div>
        <van-search v-model="keyword" placeholder="请输入员工姓名" />
        <div class="staff">
            <ul>
                <li v-for="u in staffs" :key="u.id">
                    <div class="s-i">
                        <img v-if="u.photo" :src="u.photo" />
                        <img v-else src="https://img.yangfayun.com/yfy/def_logo.jpg" />
                    </div>
                    <div class="s-d">
                        <h3>{{u.name}}</h3>
                        <div class="div">{{u.roles[0].name}}<span v-if="u.disabled">（休息）</span></div>
                        <van-button type="danger" size="small" class="btn" @click="onStaffName(u)">预  约</van-button>
                    </div>
                </li>
            </ul>
        </div>
        <Footer />
        <WxNoShare />
    </div>
</template>

<script>
    import { Search, Button } from 'vant';

    export default {
        components: {
            [Button.name]: Button,
            [Search.name]: Search
        },
        name: "subscribeStaff",
        data() {
            return {
                keyword: '',
                staffs: []
            }
        },
        created () {
            this.getStaff();
        },
        methods: {
            getStaff() {
                this.$api.get('storeProject/staff', {mid: sessionStorage.getItem('mid')}, r=>{
                    this.staffs = r.data.filter((ele) => {
                        return ele.roles[0].id != 9
                    })
                })
            },
            onStaffName(r) {
                if(r.disabled){
                    this.$toast('该员工休息中，请选择其他员工！')
                    return
                }else{
                    this.$router.push({ name: 'subscribe', query: { staffName: r.name } })
                }
            }
        }
    }
</script>

<style scoped>
    .staff{
        overflow: hidden;
        padding:10px 0;
    }
    .staff ul li{
        float: left;
        background: #ffffff;
        padding:10px;
        width: 40%;
        margin:2%;
    }
    .staff .s-i{
        height: 150px;
        overflow: hidden;
    }
    .staff .s-i img{
        width: 100%;
    }
    .staff .s-d{
        text-align: center;
    }
    .staff .s-d h3{
        margin: 8px 0 0;
    }
    .staff .s-d .div{
        display: block;
        margin: 8px 0;
    }
    .staff .s-d .btn{
        width: 100%;
    }
</style>
