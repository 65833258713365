<template>
  <div>
    <div class="subscribe" v-for="m in list" :key="m.id">
      <div class="s-l">
        <span class="s-l-m"><img :src="m.member.avatar" /></span>
      </div>
      <div class="s-m">
        <div class="s-m-d">
          <span>姓  名：<em>{{m.name}}</em></span>
          <span>手机号：<em>{{m.phone}}</em></span>
        </div>
        <div class="s-m-d">服务项目：<em>{{m.project_name}}</em></div>
        <div class="s-m-d">预约日期：<em>{{m.appointment_date}}</em></div>
        <div class="s-m-d">
          <span>服务人员：<em>{{m.staff_name}}</em></span>
          <span>状  态：<em v-if="m.status == 1">已处理</em><em class="red" v-else>未处理</em></span>
        </div>
        <div class="s-m-d">
          <div class="s-a-t">提交日期：<em>{{m.created_at}}</em></div>
          <div class="s-a-b"><van-button type="primary" size="mini" class="h-r" @click="subEdit(m)">处 理</van-button></div>
        </div>
        <div class="s-m-d" v-show="m.remarks != '' && m.remarks != null">
          备注：<em>{{m.remarks}}</em>
        </div>
      </div>
    </div>
    <!--处理-->
    <van-popup v-model:show="showEdit" position="bottom" :style="{ height: 'auto' }" closeable>
      <div class="">
        <van-form @submit="onSubmit" class="c-e-form">
          <van-field
                  v-model="info.name"
                  name="name"
                  label="姓名"
                  placeholder="请输入姓名"
                  :rules="[{ required: true, message: '请填写姓名' }]"
          />
          <van-field
                  v-model="info.phone"
                  name="phone"
                  label="手机号"
                  placeholder="请输入手机号"
                  :rules="[{ required: true, message: '请填写手机号' }]"
          />
          <!--
          <van-field
                  v-model="info.remarks"
                  rows="2"
                  autosize
                  label="备注"
                  type="textarea"
                  name="remarks"
                  maxlength="50"
                  placeholder="请输入备注"
                  show-word-limit
          />
          -->
          <van-field name="status" label="状态">
            <template #input>
              <van-radio-group v-model="status" direction="horizontal">
                <van-radio name="0">未处理</van-radio>
                <van-radio name="1">已处理</van-radio>
              </van-radio-group>
            </template>
          </van-field>
          <div style="margin: 15px;">
            <van-button round block type="primary" native-type="submit">提 交</van-button>
          </div>
        </van-form>
      </div>
    </van-popup>
  </div>
</template>

<script>
  import { Form, Popup, RadioGroup, Radio } from 'vant';

  export default {
    components: {
      [Popup.name]: Popup,
      [Form.name]: Form,
      [RadioGroup.name]: RadioGroup,
      [Radio.name]: Radio,
    },
    name: 'AdminSubscribe',
    data () {
      return {
        showEdit: false,
        list: [],
        status: '0',
        info: {
          name: '',
          phone: '',
          status: 0,
          remarks: '',
        }
      }
    },
    created () {
      this.getSubscribe()
    },
    methods: {
      getSubscribe() {
        this.$api.get('stores/subscribe', null, r=>{
          this.list = r.result
        })
      },
      subEdit(record) {
        this.showEdit = true
        this.info = record
        this.status = String(record.status)
      },
      onSubmit (values) {
        values.id = this.info.id
        this.$api.post('stores/storeSubscribe', values, r=>{
          if(r.status == 200){
            this.getSubscribe()
            this.showEdit = false
          }
        })
      }
    }
  }
</script>
<style scoped>
  .subscribe{
    overflow: hidden;
    background: #ffffff;
    padding:15px;
    margin-top: 10px;
  }
  .subscribe .s-l{
    float: left;
    width: 15%;
  }
  .subscribe .s-l .s-l-m{
    display: inline-block;
    padding-right:10px;
    margin-top:15px;
  }
  .subscribe .s-l img{
    width: 100%;
  }
  .subscribe .s-m{
    float: left;
    width: 85%;
    color: #999999;
  }
  .subscribe .s-m .s-m-d{
    overflow: hidden;
    line-height: 20px;
  }
  .subscribe .s-m .s-m-d .red{
    color:#fe786a;
  }
  .subscribe .s-m .s-m-d span{
    display: inline-block;
    width: 50%;
  }
  .subscribe .s-m .s-m-d em{
    color:#666666;
    font-style: normal;
  }
  .subscribe .s-m .s-m-d .s-a-t{
    float: left;
    width: 70%;
    line-height: 25px;
  }
  .subscribe .s-m .s-m-d .s-a-b{
    float: right;
  }
</style>
