<template>
  <div class="order-show">
    <div class="order-show-li">
      <h3 class="h3">订单信息</h3>
      <div class="o-s-li-m">
        <span>订单编号：{{ order.order_no }}</span>
        <span>下单日期：{{ order.created_at }}</span>
        <span v-if="order.pay_time != null">支付日期：{{ order.pay_time }}</span>
        <span v-if="order.close_time != null">完成日期：{{ order.close_time }}</span>
        <span>订单状态：{{ order.status_name }}</span>
      </div>
    </div>
    <div class="order-show-li">
      <h3 class="h3">商品信息</h3>
      <div class="o-main" v-for="cart in order.cart_info" :key="cart.id">
        <div class="img">
          <img :src="cart.cart_info.image" />
        </div>
        <div class="info">
          <h3>{{ cart.cart_info.title }}</h3>
          <div class="price">
            <span>￥{{ cart.cart_info.price }}</span>
            <span class="right sp2">x{{ order.total_num }}</span>
          </div>
          <div class="pay-price">
            <span class="p-sp-2">实付：￥{{ order.pay_price }}</span>
          </div>
        </div>
      </div>
    </div>

    <Floating />
    <wxNoShare />
  </div>
</template>

<script>
  export default {
    name: 'OrderDetails',
    data() {
      return {
        order: []
      };
    },
    created () {
      this.getOrders()
    },
    methods: {
      getOrders() {
        this.$api.get('storesOrders/detail', {id: this.$route.query.id}, r=>{
          this.order = r.data;
        })
      }
    }
  }
</script>

<style scoped>
  .order-show{
    padding:10px;
    overflow: hidden;
  }
  .order-show-li{
    background: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    padding:10px;
    margin-bottom: 10px;
  }
  .order-show-li .h3{
    margin: 0;
    font-size: 14px;
    padding-bottom: 5px;
  }
  .order-show-li .o-s-li-m{
    font-size: 14px;
  }
  .order-show-li .o-s-li-m span{
    display: block;
    line-height: 30px;
  }

  .order-show-li .o-main{
    overflow: hidden;
    margin-top: 5px;
  }
  .order-show-li .o-main .img{
    float: left;
    width: 35%;
  }
  .order-show-li .o-main .img img{
    width: 100%;
    height: 80px;
  }
  .order-show-li .o-main .info{
    float: right;
    width: 60%;
    margin-left: 5%;
  }
  .order-show-li .o-main .info h3{
    margin: 0;
    font-size: 14px;
    font-weight: normal;
    height: 40px;
  }
  .order-show-li .o-main .info .price{
    font-size: 14px;
    overflow: hidden;
  }
  .order-show-li .o-main .info .price .sp2{
    color:#646566;
  }
  .order-show-li .o-main .info .pay-price{
    font-size: 14px;
    margin-top: 5px;
  }
  .order-show-li .o-main .info .pay-price .p-sp-2{
    float: right;
  }
  .order-show-li .o-s-address{
    font-size: 14px;
  }
  .order-show-li .o-s-address p{
    margin: 8px 0;
  }
</style>
