<template>
  <div>

    <div class="m-title">管理</div>
    <div class="manage">
      <van-grid square @click="onIcon">
        <van-grid-item key="9" icon="label-o" text="推广海报" to="/admin/manage/poster" />
        <van-grid-item key="10" icon="qr" text="门店二维码" @click="showPopupQr" />
        <template v-if="roleId == 9 || roleId == 10">
          <van-grid-item key="1" icon="shop-o" text="门店设置" to="/admin/manage/store" />
          <van-grid-item key="2" icon="manager-o" text="员工管理" icon-color="#b5b2b2" class="i-color" />
          <van-grid-item key="3" icon="newspaper-o" text="项目管理" icon-color="#b5b2b2" class="i-color" />
          <van-grid-item key="4" icon="hot-o" text="营销管理" icon-color="#b5b2b2" class="i-color" />
          <van-grid-item key="5" icon="description" text="订单管理" icon-color="#b5b2b2" class="i-color" />
          <van-grid-item key="6" icon="cash-back-record" text="账务管理" icon-color="#b5b2b2" class="i-color" />
          <van-grid-item key="8" icon="newspaper-o" text="教育培训" icon-color="#b5b2b2" class="i-color" />
        </template>
      </van-grid>
    </div>

    <van-popup v-model:show="show">
      <img :src="`https://xy.itxwzj.com/qrcode/api.php?url=https://wx.yangfayun.com/?mid=`+userInfo.mid+`&w=5.5`" />
      <p class="p-qr">扫一扫查看门店</p>
    </van-popup>
    <AdminFooter />
    <WxNoShare />
  </div>
</template>

<script>
  import { Grid, GridItem, Popup } from 'vant';

  export default {
    components: {
      [Grid.name]: Grid,
      [GridItem.name]: GridItem,
      [Popup.name]: Popup,
    },
    name: 'AdminManage',
    data () {
      return {
        show: false,
        user: {},
        userInfo: {mid:''}
      }
    },
    computed:{
      roleId(){
        return this.$store.state.roleId
      }
    },
    created () {
      this.$api.get('rbac/users/info', null, r=>{
        this.userInfo = r.result;
      })
    },
    methods: {
      onIcon() {
      },
      showPopupQr() {
        this.show = true
      }
    }
  }
</script>
<style scoped>
  .m-title{
    font-size: 14px;
    font-weight: bold;
    padding:10px;
    overflow: hidden;
  }
  .manage{
    overflow: hidden;
  }
  .i-color >>> .van-grid-item__text{
    color:#b5b2b2;
  }
  .p-qr{
    text-align: center;
    margin: 10px;
    font-size: 14px;
  }
</style>
