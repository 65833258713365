<template>
  <div>
    <div class="orders">

      <van-tabs v-model:active="active" @click="getOrders">
        <van-tab v-for="(type, index) in typeList" :key="index" :id="type.id" :title="type.name" :to="{ name: 'OrderList', query: { status: type.id }}" >

          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="getOrders"
          >
            <div class="orders-li" v-for="(item, k) in list" :key="k">
              <div class="o-li-title">
                <span class="o-li-title-l">{{ item.order_no }}</span>
                <span class="o-li-title-r">{{ item.status_name }}</span>
              </div>
              <div class="o-main" v-for="cart in item.cart_info" :key="cart.id">
                <div class="img">
                  <img :src="cart.cart_info.image" />
                </div>
                <div class="info">
                  <h3>{{ cart.cart_info.title }}</h3>
                  <p>{{ item.store.name }}</p>
                  <div class="price">
                    <span>￥{{ cart.cart_info.price }}</span>
                    <span class="right sp2">x{{ item.total_num }}</span>
                  </div>
                  <div class="pay-price">
                    <span class="p-sp-2">实付：￥{{ item.pay_price }}</span>
                  </div>
                </div>
              </div>
              <div class="o-bottom">
                <router-link :to="{ name: 'paymentIndex', query: { id: item.id }}" v-if="item.status == 0" >
                  <span class="o-wl">去支付</span>
                </router-link>
                <span v-if="item.status == 0" class="o-wl" @click="cancel(item.id)">取消</span>
                <router-link :to="{ name: 'OrderDetails', query: { id: item.id }}">
                  <span class="o-wl">查看详情</span>
                </router-link>
              </div>
            </div>

          </van-list>
        </van-tab>
      </van-tabs>

    </div>
    <Floating skipUrl="/user/index" />
    <wxNoShare />
  </div>
</template>

<script>
  import { Tab, Tabs, List, CountDown, Dialog, Toast } from 'vant';

  export default {
    components: {
      [List.name]: List,
      [Tab.name]: Tab,
      [Tabs.name]: Tabs,
      [CountDown.name]: CountDown,
      [Dialog.Component.name]: Dialog.Component,
      [Toast.name]: Toast
    },
    name: 'orders',
    data() {
      return {
        page: 1,
        active: 0,
        typeList: [],
        list: [],
        listLength: 0,
        loading: false,
        finished: false,
        total: 0,
        title: '全部'
      };
    },
    created() {
      let title = this.$route.query.title;
      this.title = title;
      switch (title) {
        case '未付款':
          this.active = 1;
          break;
        case '已付款':
          this.active = 2;
          break;
        case '已完成':
          this.active = 3;
          break;
      }
      this.getOrders()
    },
    methods: {
      getOrders(name, title) {
        if(typeof title !== 'undefined'){
          this.title = title
        }
        this.$api.get('storesOrders/index', {page: this.page, title: this.title}, r=>{
          this.typeList = r.data.type_list;
          this.list = r.data.list.data;
          this.listLength += r.data.list.data.length;
          // this.page += 1;
          this.total = r.data.list.total;

          // 加载状态结束
          this.loading = false;
          // 数据全部加载完成
          if (this.listLength >= this.total) {
            this.finished = true;
          }
        })
      },
      cancel(id) {
        let self = this
        Dialog.confirm({
          message: '您确定要取消订单吗？',
        })
          .then(() => {
            this.$api.post('storesOrders/cancel', {id: id}, r=>{
              if(r.status === 200){
                Toast({message: '取消成功',onOpened:function(){
                  self.getOrders(self.active, self.title)
                  //location.reload()
                }})
              }
            })
          })
          .catch(() => {
            // on cancel
          });
      }
    }
  }
</script>

<style scoped>
  .orders-li{
    overflow: hidden;
    background: #ffffff;
    border-radius: 8px;
    margin: 10px;
    padding:15px;
  }
  .orders-li .o-li-title{
    font-size: 14px;
    overflow: hidden;
    margin-bottom: 10px;
  }
  .orders-li .o-li-title .o-li-title-l{
    float: left;
    font-weight: bold;
  }
  .orders-li .o-li-title .o-li-title-r{
    float: right;
    color: #ED6A0C;
  }
  .orders-li .o-main{
    overflow: hidden;
  }
  .orders-li .o-main .img{
    float: left;
    width: 35%;
  }
  .orders-li .o-main .img img{
    width: 100%;
    height: 80px;
  }
  .orders-li .o-main .info{
    float: right;
    width: 60%;
    margin-left: 5%;
  }
  .orders-li .o-main .info h3{
    margin: 0;
    font-size: 14px;
    font-weight: normal;
  }
  .orders-li .o-main .info p{
    color:#B1B1B1;
  }
  .orders-li .o-main .info .price{
    font-size: 14px;
    overflow: hidden;
  }
  .orders-li .o-main .info .price .sp2{
    color:#646566;
  }
  .orders-li .o-main .info .pay-price{
    font-size: 14px;
    margin-top: 5px;
  }
  .orders-li .o-main .info .pay-price .p-sp-2{
    float: right;
  }
  .orders-li .o-bottom{
    overflow: hidden;
    margin-top: 10px;
  }
  .orders-li .o-bottom .time{
    font-size: 12px;
    color:#6D6D6D;
  }
  .orders-li .o-bottom .o-wl{
    float: right;
    font-size: 14px;
    border:1px solid #D3D3D3;
    border-radius: 15px;
    padding: 5px 15px;
    color:#6D6D6D;
    margin-left: 12px;
  }
</style>
