<template>
    <div class="content">
        <div class="container">
            <!-- 日期列表 -->
            <div class="scroll-view scroll-view_H b-t b-b" scroll-x>
                <span v-for="(item,index) in dateArr" :key="index">
                    <div class="flex-box" @click="selectDateEvent(index,item)" :class="{ borderb: index==dateActive}">
                        <div class="date-box" :style="{color:index==dateActive?selectedTabColor:'#333'}">
                            <text class="fontw">{{item.week}}</text>
                            <text>{{item.date}}</text>
                        </div>
                    </div>
                </span>
            </div>
            <!-- 时间选项 -->
            <div class="time-box" v-if="!isSection">
                <div class="item" v-for="(item,_index) in timeArr" :key="_index">
                    <div class="item-box" :class="{'disable':item.disable,
						'active':isMultiple?item.isActive:_index==timeActive}"
                         :style="{color:isMultiple?item.isActive? selectedItemColor:'#333'
						 :_index==timeActive?selectedItemColor:'#333'}"
                         @click="selectTimeEvent(_index,item)">
                        <text>{{item.time}}</text>
                        <text class="all">{{item.disable?disableText:undisableText}}</text>
                    </div>
                </div>
            </div>
            <!-- 预约时间段 -->
            <div class="time-box" v-else>
                <span v-for="(item,_index) in timeArr" :key="_index">
                    <div class="item">
                        <div class="item-box" :class="{'disable':item.disable || item.isInclude,
						'active':item.time == timeQuanBegin || item.time == timeQuanEnd }"
                              :style="{color:item.time == timeQuanBegin || item.time == timeQuanEnd? selectedItemColor:'#333'}"
                              @click="handleSelectQuantum(_index,item)">
                            <text>{{item.time}}</text>
                            <text class="all">{{item.disable?disableText:undisableText}}</text>
                        </div>
                    </div>
                </span>
            </div>
        </div>
        <div class="bottom">
            <div class="show-time" v-if="!isMultiple && !isSection">
                预约时间：{{orderDateTime}}
            </div>
            <button form-type="submit" type="default" size="mini" class="submit-btn" @click="handleSubmit">
                确认预约
            </button>
        </div>
    </div>
</template>

<script>
    /* eslint-disable */
    import { initData, initTime, currentTime } from '../utils/date.js'
    export default {
        name: 'times',
        model: {
            prop: "showPop",
            event: "change"
        },
        props: {
            isMultiple: { //是否多选
                type: Boolean,
                default: false
            },
            isSection: { //预约时间段
                type: Boolean,
                default: false
            },
            disableText: { //禁用显示的文本
                type: String,
                default: "已约满"
            },
            undisableText: { //未禁用显示的文本
                type: String,
                default: "可预约"
            },
            timeInterval: { // 时间间隔，小时为单位
                type: Number,
                default: 1
            },
            selectedTabColor: { // 日期栏选中的颜色
                type: String,
                default: "#FB4B5C"
            },
            selectedItemColor: { // 时间选中的颜色
                type: String,
                default: "#FB4B5C"
            },
            beginTime: {
                type: String,
                default: "09:00"
            },
            endTime: {
                type: String,
                default: "21:00"
            },
            appointTime: { // 预约的时间
                type: Array,
                default() {
                    return []
                }
            },
            disableTimeSlot: { // 预约开始和结束时间，来禁用时间段
                type: Object,
                default() {
                    return {}
                }
            }
        },
        watch: {
            appointTime: {
                handler(val) {
                    if (val && val.length) {
                        this.initOnload()
                    }
                }
            }
        },
        data() {
            return {
                orderDateTime: '暂无选择', // 选中时间
                orderTimeArr: {}, //多选的时间
                dateArr: [], //日期数据
                timeArr: [], //时间数据
                nowDate: "", // 当前日期
                dateActive: 0, //选中的日期索引
                timeActive: 0, //选中的时间索引
                timeQuanBeginIndex: 0, //时间段开始的下标
                selectDate: "", //选择的日期
                selectTime: "", //选择的时间
                timeQuanBegin: "", //时间段开始时间
                timeQuanEnd: "", //时间段结束时间
            }
        },
        created(props) {
            this.selectDate = this.nowDate = currentTime().date
            this.initOnload()
        },
        methods: {
            initOnload() {
                this.dateArr = initData() // 日期栏初始化
                this.timeArr = initTime(this.beginTime, this.endTime, this.timeInterval) //时间选项初始化
                this.timeQuanBegin = this.timeQuanEnd = ""

                let isFullTime = true
                console.log(this.timeArr)
                this.timeArr.forEach((item, index) => {

                    //判断是当前这一天，选中时间小于当前时间则禁用
                    if (this.selectDate == this.nowDate && currentTime().time > item.time) {
                        item.disable = true
                    }

                    // 将预约的时间禁用
                    this.appointTime.forEach(t => {
                        let [date, time] = t.split(' ')
                        if (date == this.selectDate && item.time == time) {
                            item.disable = true
                        }
                    })

                    // 禁用时间段
                    const cur_time = `${this.selectDate} ${item.time}`
                    const {begin_time, end_time} = this.disableTimeSlot
                    if (begin_time && end_time && (begin_time <= cur_time && cur_time <= end_time)) {
                        item.disable = true
                    }

                    // 判断是否当前日期时间都被预约
                    if (!item.disable) {
                        isFullTime = false
                    }
                    this.isSection && (item.isInclude = false)
                })

                this.orderDateTime = isFullTime ? "暂无选择" : this.selectDate
                this.timeActive = -1
                for (let i = 0, len = this.timeArr.length; i < len; i++) {
                    if (!this.timeArr[i].disable) {
                        this.orderDateTime = `${this.selectDate} ${this.timeArr[i].time}`
                        this.timeActive = i
                        return
                    }
                }
            },

            // 日期选择事件
            selectDateEvent(index, item) {
                this.dateActive = index
                this.selectDate = item.date
                this.initOnload()
            },

            // 时间选择事件
            selectTimeEvent(index, item) {
                if (item.disable) return
                if (this.isMultiple) {
                    item.isActive = !item.isActive
                    this.timeArr = this.timeArr.slice()
                    this.orderTimeArr[this.selectDate] = this.timeArr.reduce((prev, cur) => {
                        cur.isActive && prev.push(cur.time)
                        return prev
                    }, [])
                } else {
                    this.timeActive = index
                    this.selectTime = item.time
                    this.orderDateTime = `${this.selectDate} ${item.time}`
                }
            },

            // 选择时间段
            handleSelectQuantum(index, item) {
                if (item.disable) return

                function clearTime() {
                    this.timeQuanBeginIndex = index
                    this.timeQuanBegin = item.time
                    this.timeQuanEnd = ""
                }

                if (!this.timeQuanBegin) {
                    clearTime.call(this)
                    return
                }
                if (!this.timeQuanEnd && this.timeQuanBegin) {
                    let isDisble = false
                    let start = this.timeQuanBeginIndex
                    let end = index
                    start > end && ([start, end] = [end, start])
                    for (let i = start + 1; i < end; i++) {
                        if (this.timeArr[i].disable) {
                            isDisble = true
                            clearTime.call(this)
                            return
                        }
                    }
                    if (!isDisble) {
                        for (let i = start + 1; i < end; i++) {
                            this.timeArr[i].isInclude = true
                        }
                    }
                    this.timeQuanEnd = item.time
                    return
                }

                if (this.timeQuanBegin && this.timeQuanEnd) {
                    this.timeArr.forEach(t => {
                        t.isInclude = false
                    })
                    clearTime.call(this)
                }

            },
            handleChange() {
                this.timeQuanBegin > this.timeQuanEnd && ([this.timeQuanBegin, this.timeQuanEnd] = [this.timeQuanEnd, this.timeQuanBegin])
            },
            handleSubmit() {
                if (this.isSection) {
                    this.handleChange()
                    this.$emit('change', {
                        beginTime: `${this.selectDate} ${this.timeQuanBegin}`,
                        endTime: `${this.selectDate} ${this.timeQuanEnd}`
                    })
                    return
                }

                if (this.isMultiple) {
                    let time = []
                    for (let date in this.orderTimeArr) {
                        this.orderTimeArr[date].forEach(item => {
                            time.push(`${date} ${item}`)
                        })
                    }
                    this.$emit('change', time)
                } else {
                    this.$emit('change', this.orderDateTime)
                }

            }
        }
    }
</script>
<style lang="scss" scoped>
    /* eslint-disable */
    @import './pretty-times.scss';

    page {
        height: 100%;
    }

    .content {
        text-align: center;
        height: 100%;
    }

    /* 两个按钮 */
    .bottom {
        display: flex;
        flex-direction: row;
        position: fixed;
        bottom: 8px;
        top: auto;
        left: 0px;
        width: 100%;
        background-color: #fff;
    }

    .show-time {
        width: 70%;
        height: 47px;
        color: #505050;
        background-color: rgba(255, 255, 255, 1);
        font-size: 15px;
        line-height: 47px;
        text-align: center;
    }

    .submit-btn {
        width: 25%;
        height: 40px;
        color: white;
        background-color: #CA89FF;
        font-size: 15px;
        line-height: 40px;
        text-align: center;
        margin: auto;
        padding: 0;
        border: none;
    }

    .fontw {
        font-weight: bold;
    }

    .borderb {
        border-bottom: 2px solid #FB4B5C;
    }
</style>
