<template>
  <div>
    <div class="f-h"></div>
    <div class="footer">
      <van-tabbar v-model="active" active-color="#EE0A24">
        <van-tabbar-item icon="wap-home-o" @click="toUrl('home', 0)">主页</van-tabbar-item>
        <van-tabbar-item icon="apps-o" @click="toUrl('project', 1)">项目</van-tabbar-item>
        <van-tabbar-item icon="todo-list-o" @click="toUrl('subscribe', 2)">预约</van-tabbar-item>
        <van-tabbar-item icon="gift-o" @click="toUrl('activity', 3)">活动</van-tabbar-item>
        <van-tabbar-item icon="manager-o" @click="toUrl('user', 4)">我的</van-tabbar-item>
      </van-tabbar>
    </div>
  </div>
</template>

<script>
    export default {
      data () {
        return {
          active: 0
        }
      },
      created () {
        let routeName = this.$route.name
        if (routeName == 'home') {
          this.active = 0
        }else if (routeName == 'project' || routeName == 'projectDetails') {
          this.active = 1
        }else if (routeName == 'subscribe' || routeName == 'subscribeStaff') {
          this.active = 2
        }else if (routeName == 'activity') {
          this.active = 3
        }else if (routeName == 'user' || routeName == 'UsersSetting' || routeName == 'UsersEdit') {
          this.active = 4
        }
      },
      methods: {
          toUrl (name, type) {
            this.active = type
            this.$router.push({name: name, query: { mid: sessionStorage.getItem('mid') }})
          }
      }
    }
</script>

<style scoped>

</style>
