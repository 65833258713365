<template>
  <div>
    <div class="setting">
      <ul>
        <li>
          <router-link :to="{ name: 'UsersEdit'}">
            <span class="title">个人资料</span>
            <span class="right s-icon">
              <van-icon name="arrow" />
            </span>
          </router-link>
        </li>
        <!--
        <li>
          <router-link :to="{ name: 'UsersSecurity'}">
            <span class="title">账户与安全</span>
            <span class="right s-icon">
              <van-icon name="arrow" />
            </span>
          </router-link>
        </li>
        -->
        <li>
          <a @click="clearCache">
            <span class="title">清除缓存</span>
            <span class="right s-icon">
              <van-icon name="arrow" />
            </span>
          </a>
        </li>
      </ul>
    </div>
    <van-button type="default" class="reg-btn" @click="loginOut">退出登录</van-button>

    <Footer />
    <wxNoShare />
  </div>
</template>

<script>
  import { Icon, Button } from 'vant';

  export default {
    components: {
      [Icon.name]: Icon,
      [Button.name]: Button
    },
    name: 'index',
    methods: {
      loginOut() {
        this.$store.commit('logout')
        this.$router.push('/')
      },
      clearCache() {
        this.$store.dispatch('refreshToken', sessionStorage.getItem('token'))
        this.$store.dispatch('logined', sessionStorage.getItem('token'))
        this.$toast('清除成功')
      }
    }
  }
</script>

<style scoped>
  .setting{
    overflow: hidden;
    padding:15px;
    background: #ffffff;
  }
  .setting ul li{
    color:#3e3f3f;
    border-bottom: 1px solid #F7F7F9;
  }
  .setting ul li a{
    display: block;
    padding:15px 0;
  }
  .setting ul li:last-child{
    border-bottom: none;
  }
  .setting ul li .s-icon{
    font-size: 18px;
  }
  .setting ul li .title{
    font-size: 14px;
  }
  .reg-btn{
    background: #EE0A24;
    color:#fff;
    width: 80%;
    border-radius: 25px;
    margin: 20px auto 0;
    display: inherit;
  }
</style>
